import { Injectable } from '@angular/core';
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth-service';
import { User } from '../api/domain-impl';
import { ReplaySubject, take } from 'rxjs';
import { CountryList } from './translate';

export class AuthData {
	constructor(public allow: string[]) {}
}

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	private static canAccess(route: ActivatedRouteSnapshot, user: User) {
		if (!user) {
			return false;
		}
		if (route.data && route.data['authorization']) {
			const auth: AuthData = <AuthData>route.data['authorization'];
			if (auth.allow) {
				for (let i = 0; i < auth.allow.length; i++) {
					const role = auth.allow[i];
					if (user.hasRole(role)) {
						return true;
					}
				}
				return false;
			}
			return true; // logged in but no restrictions
		}
	}

	constructor(private authService: AuthService, private router: Router) {}

	get lang() {
		const browserLang = navigator.language.slice(0, 2);
		return this.checkValidLang(browserLang);
	}

	private checkValidLang(lang: string): string {
		const country = CountryList.find((country) => country.key === lang);
		if (country) return country.key;
		else return 'en';
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): ReplaySubject<boolean> {
		const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
		this.authService.currentUser.pipe(take(1)).subscribe((cu) => {
			if (AuthGuard.canAccess(route, cu)) {
				subject.next(true);
			} else {
				const urlArray = state.url.split('/');
				if (urlArray.length > 3 && urlArray[1] === 'app') {
					urlArray[2] = !state.url.includes('reports/engine')
						? this.lang
						: this.checkValidLang(urlArray[2]);
					this.authService.redirectUrl = urlArray.toString().replace(/,/g, '/');
				}
				console.log('Access to route denied: ', route);
				console.log('Redirecting to login page...');
				this.router.navigateByUrl('/auths/' + this.lang + '/login');
				subject.next(false);
			}
		});
		return subject;
	}
}
