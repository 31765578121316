import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import {catchError, map, mergeMap, share} from 'rxjs/operators';
import {LogBookItem, NiagaraAlertConfiguration, NiagaraThresholds, User} from './domain-impl';
import {IChartType} from '../d3-charts/chart-domains';
import {AuthService} from '../shared/auth-service';
import {IHALObject, IHALPage} from '../shared/hal/domain-model';
import {HalDiscoveryService} from '../shared/hal/hal-discovery.service';
import {
	ICompany,
	ILogBookItem,
	IMachine,
	IMachineMetricValues,
	IMachinesResourcesResponse,
	IMachinesSpecResourcesResponse,
	IMaintenance,
	IMaintenanceResourcesResponse,
	IMetricSpec,
	INiagaraAlertConfiguration,
	INiagaraThresholds,
	IReportConfig,
	IReportConfigResourcesResponse,
	IReportUpdation,
	ISensorData,
	IServiceRequest,
	ISpectrumHistoryTimes,
	ITimeSeriesAtTimestampResponse,
	ITimeSeriesByIdentifiersResponse,
	ITimeSeriesByIdentifiersResponseData,
	IUser,
	TimeSeriesObject,
	TimeSeriesStringObject
} from './domain-model';

export interface ITimeSeriesByIdentifiersDefinition {
	identifiers: Array<string>;
	startTime: number;
	endTime: number;
	groupingIntervalInSeconds: number;
}

@Injectable({ providedIn: 'root' })
export class MachineService {
	private machineObservables: {
		[key: number]: Observable<IMachineMetricValues>;
	} = [];
	private machineCache: Observable<Array<IMachine>> = null;
	private currentUser: User;
	private IReportUpdation: { operation: string; config: IReportConfig };
	private machineReportConfigs = new Subject<IReportUpdation>();

	constructor(
		private authService: AuthService,
		private disco: HalDiscoveryService,
		private http: HttpClient
	) {}

	getReportConfig(machine: IMachine): Observable<Array<IReportConfig>> {
		return this.http.get(machine._links['hb:reportConfig'].href).pipe(
			catchError((err: HttpErrorResponse) => {
				return throwError(() => err.error);
			}),
			map((r) => {
				let response = <IReportConfigResourcesResponse>r;
				if (
					response._embedded &&
					response._embedded['hb:reportConfigResources']
				) {
					return <Array<IReportConfig>>(
						response._embedded['hb:reportConfigResources']
					);
				}
				return [];
			})
		);
	}

	/** this is the main method to load a machine after the router decided to load /machine/ID */
	load(id: number): Observable<IMachine> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				// we join two API calls together in the frontend to be faster than JPA
				let call_1 = this.http.get(
					api.metrics.hb.machine.uriTemplate.expand({ machineId: id })
				);
				// let call_2 = this.http.get(api.metrics.hb.metricspecByMachine.uriTemplate.expand({ machineId: id })
				// 	+ '?page=0&size=9999&sort='); // botched the paging, cause uriTemplate did not work here
				let call_3 = this.http.get(
					api.metrics.hb.reportConfigByMachine.uriTemplate.expand({
						machineId: id
					})
				);
				// ------- reportconfig here
				return forkJoin([
					call_1,
					// call_2,
					call_3
				]).pipe(
					map((r) => {
						let response = <IMachine>r[0];
						// let responseMetricSpecifications = <IMachinesSpecResourcesResponse>r[1];
						let reportConfig = <IReportConfig>r[1];

						// response.metricSpecByAlias = {};
						// if (responseMetricSpecifications.page.totalElements !== 0) {
						// 	// add metrics-specs to the machine object
						// 	response.metricSpecByAlias = (Object as any).assign(...responseMetricSpecifications._embedded['hb:metricSpecResources']
						// 		.map(d => ({ [d['alias']]: d['path'] })));
						// }

						if (reportConfig.page.totalElements !== 0) {
							// add report config to the machine object
							response.reportConfig = <Array<IReportConfig>>(
								reportConfig._embedded['hb:reportConfigResources']
							);
						} else {
							response.reportConfig = <Array<IReportConfig>>[];
						}

						// need to prepare URITemplate for save metricspec variable configurable values (min,max)
						this.disco.decorateLinks(response);
						return response;
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	/** this private method adds the specs to the machine - adding by JPA took too much time */
	private postprocessMachine(machine: IMachine): IMachine {
		this.disco.decorateLinks(machine);

		return machine;
	}

	/**
	 * returns a dictionary:
	 * machineId -> metricAlias -> metricSpec
	 * @returns {Observable<R>}
	 */
	getMetricMap(): Observable<any> {
		return this.listMyMachines().pipe(
			map((machines) => {
				let lookup: any = {};
				machines.forEach((machine: IMachine) => {
					lookup[machine.id] = {};
					machine.metricSpecs.forEach((ms: IMetricSpec) => {
						lookup[machine.id][ms.alias] = ms;
					});
				});
				return lookup;
			}),
			share({
				connector: () => new ReplaySubject(1),
				resetOnError: false,
				resetOnComplete: false,
				resetOnRefCountZero: false
			})
		);
	}

	writeValue(machine: IMachine, value: any): Observable<Response> {
		return this.http
			.post(machine._links['hb:write'].href, value)
			.pipe(map((r) => <Response>r));
	}

	// update machine details
	public updateValue(machine: IMachine): Observable<any> {
		//  machine.company=null;
		let uri = machine._links['hb:update'].href;
		return this.http.put(uri, machine, { observe: 'response' });
	}

	// Delete
	// deleteAssociation(machine: IMachine): Observable<any> {
	// 	let uri = machine._links['hb:delete'].href;
	// 	return this.http.delete(uri).pipe(map(r => r));
	// }

	// sooooo old remove ?
	// todo: currenty we stream all values of a machine, regardless if they
	// todo: have changed since the last poll or not.
	// todo: to improve performance the client can post a timestamp to constrain
	// todo: the query on the backend. backend should deliver the oldest timestamp
	// todo: seen in the set of metrics queried from the database to the client.
	/*streamMetricValues(machine: IMachine): Observable<IMachineMetricValues> {
		if (machine && machine.id) {

			if (!this.machineObservables[machine.id]) {
				let observable = <Observable<IMachineMetricValues>>
					interval(5000)
						.timeInterval()
						.flatMap(() => {
							return this.http.get(machine._links['hb:currentValues'].href)
								.map(r => r);
						});

				let multi = observable.multicast(() => new Subject<IMachineMetricValues>());
				multi.connect();
				this.machineObservables[machine.id] = multi;
			}
			return (this.machineObservables[machine.id]);
		}
	}*/

	listMyMachines(): Observable<Array<IMachine>> {
		this.currentUser = this.authService.userSnapshot;
		let companyId =
			this.currentUser.selectedCompany || this.currentUser.companyId;

		this.machineCache = this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let url = api.metrics.hb.myMachines.uriTemplate.expand({ companyId }),
					murl = api.metrics.hb.metricspecByCompany.uriTemplate.expand({
						companyId
					});

				return forkJoin([this.http.get(url), this.http.get(murl)]).pipe(
					map((r) => {
						let response = <IMachinesResourcesResponse>r[0];
						let resSPec = <IMachinesSpecResourcesResponse>r[1];
						if (
							response._embedded &&
							response._embedded['hb:machineResources']
						) {
							response._embedded['hb:machineResources'].forEach(
								(machine: IMachine) => {
									if (
										resSPec._embedded &&
										response._embedded['hb:metricSpecResources']
									) {
										machine.metricSpecs = resSPec._embedded[
											'hb:metricSpecResources'
										].filter((spec) => spec.machineId === machine.id);
									}
									this.postprocessMachine(machine);
								}
							);
							return <Array<IMachine>>response._embedded['hb:machineResources'];
						}
						return null;
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
		setTimeout(() => {
			this.machineCache = null;
		}, 5000);

		return this.machineCache;
	}

	listMachineNames(companyId: number): Observable<Array<IMachine>> {
		this.machineCache = this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let url = api.metrics.hb.myMachines.uriTemplate.expand({
					companyId: companyId,
					size: 1000,
					page: 0,
					sort: 'name'
				});

				return this.http.get(url).pipe(
					map((r) => {
						let response = <IMachinesResourcesResponse>r;
						if (
							response._embedded &&
							response._embedded['hb:machineResources']
						) {
							response._embedded['hb:machineResources'].forEach(
								(machine: IMachine) => {
									this.postprocessMachine(machine);
								}
							);
							return <Array<IMachine>>response._embedded['hb:machineResources'];
						}
						return null;
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);

		return this.machineCache;
	}

	/**Uri Template added to response  */
	public listMachines(page: IHALPage): Observable<IHALObject> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http
					.get(
						api.metrics.hb.machines.uriTemplate.expand({
							size: page.size,
							page: page.number,
							sort: page.sort
						})
					)
					.pipe(
						map((r) => {
							let response = <IHALObject>r;
							response._embedded['hb:machineResources'].forEach(
								(machine: IMachine) => {
									this.postprocessMachine(machine);
								}
							);
							return response;
						})
					);
			})
		);
	}

	public listDummyMachines(page: IHALPage): Observable<IHALObject> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http
					.get(
						api.metrics.hb.dummyMachines.uriTemplate.expand({
							size: page.size,
							page: page.number,
							sort: page.sort
						})
					)
					.pipe(
						map((r) => {
							let response = <IHALObject>r;
							if (
								response._embedded &&
								response._embedded['hb:machineResources']
							) {
								response._embedded['hb:machineResources'].forEach(
									(machine: IMachine) => {
										this.postprocessMachine(machine);
									}
								);
							}

							return response;
						})
					);
			})
		);
	}

	public addNewDummyMachine(machine: IMachine): Observable<IMachine> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http
					.post(api.metrics.hb.dummyMachines.uri, machine)
					.pipe(map((r) => <IMachine>r));
			})
		);
	}

	public deleteDummyMachine(machine: IMachine): Observable<IMachine> {
		return this.http
			.delete(machine._links['hb:delete'].href)
			.pipe(map((r) => <IMachine>r));
	}

	listChildCompanies(user: IUser, page: IHALPage): Observable<Array<ICompany>> {
		const uri = user._links['hb:companiesByParent'].uriTemplate.expand({
			size: page.size,
			page: page.number,
			sort: page.sort
		});
		return this.http.get(uri).pipe(
			map((r) => {
				let response = <IHALObject>r;
				return <Array<ICompany>>response._embedded['hb:companyResources'];
			})
		);
	}

	getChartTypes(machine: IMachine): Observable<Array<IChartType>> {
		return this.http.get(machine._links['hb:chartTypes'].href).pipe(
			map((r) => {
				let response = <IHALObject>r;
				if (response._embedded && response._embedded['hb:chartResources']) {
					return <Array<IChartType>>response._embedded['hb:chartResources'];
				}
				return null;
			})
		);
	}

	updateChartType(
		machine: IMachine,
		resource: IChartType
	): Observable<IChartType> {
		return this.http
			.post(machine._links['hb:chartTypes'].href, resource)
			.pipe(map((r) => <IChartType>r));
	}

	getMaintAids(machine: IMachine): Observable<Array<IMaintenance>> {
		return this.http.get(machine._links['hb:maintenance'].href).pipe(
			map((r) => {
				let response = <IMaintenanceResourcesResponse>r;
				if (
					response._embedded &&
					response._embedded['hb:maintenanceResources']
				) {
					return <Array<IMaintenance>>(
						response._embedded['hb:maintenanceResources']
					);
				}
				return <Array<IMaintenance>>[];
			})
		);
	}

	createReportConfig(
		machine: IMachine,
		resource: IReportConfig
	): Observable<IReportConfig> {
		return this.http
			.post(machine._links['hb:reportWrite'].href, resource)
			.pipe(map((r) => <IReportConfig>r));
	}

	updateReportConfig(resource: IReportConfig): Observable<IReportConfig> {
		return this.http
			.post(resource._links['hb:update'].href, resource)
			.pipe(map((r) => <IReportConfig>r));
	}

	updateMaintAids(resource: IMaintenance): Observable<IMaintenance> {
		return this.http
			.post(resource._links['hb:update'].href, resource)
			.pipe(map((r) => <IMaintenance>r));
	}

	getTimeSeriesByAliasList(
		machineId: number,
		alias: Array<string>,
		minutesStart: number,
		groupingIntervalInSeconds?: number
	): Observable<any> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let timeSeriesApis = [] as Observable<any>[];
				alias.forEach((aliasValue) =>
					timeSeriesApis.push(
						this.http.get(
							api.metrics.hb.timeseriesbyalias.uriTemplate.expand({
								machineId: machineId,
								alias: aliasValue,
								minutesStart: minutesStart,
								groupingIntervalInSeconds: groupingIntervalInSeconds
									? Math.round(groupingIntervalInSeconds)
									: 0
							})
						)
					)
				);

				// we join two API calls together in the frontend to be faster than JPA
				return forkJoin(timeSeriesApis).pipe(
					map((result) => result),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	getTimeSeriesByMetricPath4MultilineChart(
		metricspecPaths: Array<string>,
		timestampStart: number,
		timestampEnd: number,
		numberOfPoints: number = 1000,
	): Observable<any> {
		const timespanInSecond = timestampEnd - timestampStart;
		const groupTimeForFixedNoOfPoints = Math.floor(timespanInSecond / numberOfPoints);

		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let timeSeriesApis = [] as {data: Observable<any>, metricspecPath: string}[];
				metricspecPaths.forEach((metricspecPathValue) =>
					timeSeriesApis.push(
						{
						data: this.http.get(
							api.metrics.hb.timeSeriesByMetricPath.uriTemplate.expand({
								metricspecPath: metricspecPathValue,
								timestampStart: timestampStart,
								timestampEnd: timestampEnd,
								groupingIntervalInSeconds: groupTimeForFixedNoOfPoints
								//groupingIntervalInSeconds: groupingIntervalInSeconds
								//	? Math.round(groupingIntervalInSeconds)
								//	: 0
							})
						),
						metricspecPath: metricspecPathValue
						}
					)
				);

				// we join two API calls together in the frontend to be faster than JPA
				return forkJoin(timeSeriesApis.map((ts) => ts.data)).pipe(
					map((response: any) => {
						if (response && response.length > 0) {
							return response.map((result, index) => {
								if (
									result._embedded &&
									result._embedded['hb:timeSeriesResources']
								) {
									return {metricspecPath: timeSeriesApis[index].metricspecPath, data: result._embedded['hb:timeSeriesResources']};
								}
							});
						}
						return null;
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	getTimeSeriesByMetricPath(
		metricspecPaths: Array<string>,
		timestampStart: number,
		timestampEnd: number,
		numberOfPoints: number = 1000,
	): Observable<any> {
		const timespanInSecond = timestampEnd - timestampStart;
		const groupTimeForFixedNoOfPoints = Math.floor(timespanInSecond / numberOfPoints);

		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let timeSeriesApis = [] as Observable<any>[];
				metricspecPaths.forEach((metricspecPathValue) =>
					timeSeriesApis.push(
						this.http.get(
							api.metrics.hb.timeSeriesByMetricPath.uriTemplate.expand({
								metricspecPath: metricspecPathValue,
								timestampStart: timestampStart,
								timestampEnd: timestampEnd,
								groupingIntervalInSeconds: groupTimeForFixedNoOfPoints
								//groupingIntervalInSeconds: groupingIntervalInSeconds
								//	? Math.round(groupingIntervalInSeconds)
								//	: 0
							})
						)
					)
				);

				// we join two API calls together in the frontend to be faster than JPA
				return forkJoin(timeSeriesApis).pipe(
					map((response: any) => {
						if (response && response.length > 0) {
							return response.map((result) => {
								if (
									result._embedded &&
									result._embedded['hb:timeSeriesResources']
								) {
									return result._embedded['hb:timeSeriesResources'];
								}
							});
						}
						return null;
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	getTimeSeriesByIdentifiers(
		identifiers: Array<string>,
		startTime: number,
		endTime: number,
		numberOfPoints: number = 1000
	): Observable<any> {
		const timespanInSecond = endTime - startTime;
		const groupTimeForFixedNoOfPoints = Math.floor(timespanInSecond / numberOfPoints);

		return this.disco
			.getResourceTree()
			.pipe(
				mergeMap((api) => {
					let restCalls = [];
					for (let identifier of identifiers) {
						restCalls.push(this.http.get(
							api.metrics.hb.timeSeriesByIdentifiers.uriTemplate.expand({
								identifiers: [identifier],
								timestampStart: startTime,
								timestampEnd: endTime,
								groupingIntervalInSeconds: groupTimeForFixedNoOfPoints
								//groupingIntervalInSeconds: groupingIntervalInSeconds
								//	? Math.round(groupingIntervalInSeconds)
								//	: 0
							})
						));
					}
					return forkJoin(restCalls);
				}),
				map((responses: ITimeSeriesByIdentifiersResponse[]) => {
					const extractedData = {} as ITimeSeriesByIdentifiersResponseData;
					for (let response of responses) {
						for (let key of Object.keys(response)) {
							extractedData[key] = response[key].content;
						}
					}
					return extractedData;
				})
			)
	}

	timeSeriesStringsByIdentifiers(
		identifiers: Array<string>,
		timestampStart: number,
		timestampEnd: number,
	): Observable<Array<Array<TimeSeriesStringObject>>> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let timeSeriesApis = [] as Observable<any>[];
				identifiers.forEach((identifier: String) =>
					timeSeriesApis.push(
						this.http.get(
							api.metrics.hb.timeSeriesStringsByIdentifier.uriTemplate.expand({
								identifier: identifier,
								timestampStart,
								timestampEnd
							})
						)
					)
				);

				// we join two API calls together in the frontend to be faster than JPA
				return forkJoin(timeSeriesApis).pipe(
					map((response: any) => {
						if (response && response.length > 0) {
							return response.map((result) => {
								if (
									result._embedded &&
									result._embedded['hb:timeSeriesStringResources']
								) {
									return result._embedded['hb:timeSeriesStringResources'] as Array<Array<TimeSeriesStringObject>>;
								}
							});
						}
						return null;
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	getLastAndFirstMetricOfYears(metricspecPath: string): Observable<Map<number, TimeSeriesObject[]>> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.lastAndFirstMetricOfYears.uriTemplate.expand({
						metricspecPath: metricspecPath
					})
				).pipe(
					map((result: any) => {
						if (!result) {
							return null;
						} else {
							let converted: Map<number, TimeSeriesObject[]> = new Map();

							Object.entries(result).forEach((year) => {
								converted.set(+year[0], year[1]['content']);
							})

							return converted;
						}
					})
				)}
			)
		);
	}

	getLastAndFirstMetricOfMonths(metricspecPath: string, year?: number): Observable<Map<number, Map<number, TimeSeriesObject[]>>> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.lastAndFirstMetricOfMonths.uriTemplate.expand({
						metricspecPath: metricspecPath
					})
				).pipe(
					map((result: any) => {
						if (!result) {
							return null;
						} else {
							let converted: Map<number, Map<number, TimeSeriesObject[]>> = new Map();

							Object.entries(result).forEach((year) => {
								let monthsData: Map<number, TimeSeriesObject[]> = new Map();
								Object.entries(year[1]).forEach((month) => {
									monthsData.set(+month[0], month[1]['content']);
								})
								converted.set(+year[0], monthsData);
							})

							return converted;
						}
					})
				)}
			)
		);
	}

	getLastAndFirstMetricOfTimespan(metricspecPath: string, startTimestamp: number, endTimestamp: number): Observable<TimeSeriesObject[]> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.lastAndFirstMetricOfTimespan.uriTemplate.expand({
						metricspecPath: metricspecPath,
						startTimestamp: startTimestamp,
						endTimestamp: endTimestamp
					})
				).pipe(
					map((response: any) => {
						if (response && response._embedded && response._embedded['hb:timeSeriesResources']) {
							return response._embedded['hb:timeSeriesResources'];
						} else {
							return null;
						}
					})
				)}
			)
		);
	}

	getLatestMetricsBeforeTimestamp(metricspecPaths: string[], timestamp: number, avoidValueAtTimestamp: boolean): Observable<Map<string, TimeSeriesObject>> {
		if (!metricspecPaths || metricspecPaths.length === 0) {
			return of(new Map());
		}
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.latestMetricSpecsBeforeTimestamp.uriTemplate.expand({
						metricspecPaths,
						timestamp,
						avoidValueAtTimestamp
					})
				).pipe(
					map((results: Map<string, TimeSeriesObject>) => {
						if (!results) {
							return null;
						} else {
							return new Map(Object.entries(results));
						}
					})
				)}
			)
		);
	}


	getLatestStringValueBeforeTimestamp(metricspecPaths: string[], timestamp: number, avoidValueAtTimestamp: boolean): Observable<Map<string, TimeSeriesStringObject>> {
		if (!metricspecPaths || metricspecPaths.length === 0) {
			return of(new Map());
		}
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.latestStringValueBeforeTimestamp.uriTemplate.expand({
						metricspecPaths,
						timestamp,
						avoidValueAtTimestamp
					})
				).pipe(
					map((results: Map<string, TimeSeriesStringObject>) => {
						if (!results) {
							return null;
						} else {
							return new Map(Object.entries(results));
						}
					})
				)}
			)
		);
	}


	getUniqueMetrics(metricspecPaths: string[]): Observable<Map<string, TimeSeriesObject[]>> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.uniqueMetricSpecs.uriTemplate.expand({
						metricspecPaths: metricspecPaths
					})
				).pipe(
					map((results: any) => {
						if (!results) {
							return null;
						} else {
							let converted = new Map<string, TimeSeriesObject[]>();

							for (const [key, value] of Object.entries(results)) {
								converted.set(key, value['content']);
							}

							return converted;
						}
					})
				)}
			)
		);
	}

	getStartAndEndOfMetricValue(metricspecPath: string, value: number): Observable<TimeSeriesObject[]> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.startAndEndOfMetricSpecValue.uriTemplate.expand({
						metricspecPath: metricspecPath,
						value: value
					})
				).pipe(
					map((response: any) => {
						if (response && response._embedded && response._embedded['hb:timeSeriesResources']) {
							return response._embedded['hb:timeSeriesResources'];
						} else {
							return null;
						}
					})
				)}
			)
		);
	}

	getAllStartAndEndOfMetricValue(metricspecPath: string, value: number): Observable<TimeSeriesObject[]> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.allStartAndEndOfMetricSpecValue.uriTemplate.expand({
						metricspecPath: metricspecPath,
						value: value
					})
				).pipe(
					map((response: any) => {
						if (response && response._embedded && response._embedded['hb:timeSeriesResources']) {
							return response._embedded['hb:timeSeriesResources'];
						} else {
							return null;
						}
					})
				)}
			)
		);
	}

	getSumOfDifferencesOfAllStartAndEndForMetricSpecValue(metricspecPath: string, value: number): Observable<number> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.sumOfDifferencesOfAllStartAndEndForMetricSpecValue.uriTemplate.expand({
						metricspecPath: metricspecPath,
						value: value
					})
				).pipe(
					map((response: number) => {
						return response;
					})
				)}
			)
		);
	}

	getMetricspecByPath(metricSpecPath: string): Observable<IMetricSpec> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http
					.get(
						api.metrics.hb.metricspecByPath.uriTemplate.expand({
							metricSpecPath: metricSpecPath,
						})
					)
					.pipe(
						map((r) => {
							let response = <IMetricSpec>r;
							return response;
						})
					);
			})
		);
	}
	getLastUpdatedAt(machineId: number, alias: string) {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http
					.get(
						api.metrics.hb.metricspecByMachineAndAlias.uriTemplate.expand({
							alias: alias,
							machineId: machineId
						})
					)
					.pipe(
						map((r) => {
							let response = <IMetricSpec>r;
							return response;
						})
					);
			})
		);
	}

	createLogBookItem(
		machine: IMachine,
		logBookItem: LogBookItem
	): Observable<LogBookItem> {
		return this.http
			.post(machine._links['hb:createLogBookItem'].href, logBookItem)
			.pipe(map((r) => <LogBookItem>r));
	}

	getLogBookItems(machine: IMachine, page: IHALPage): Observable<any> {
		let pagingUrlParam = `?page=${page.number}&size=${page.size}&sort=${page.sort}`;
		return this.http
			.get(machine._links['hb:logBookItems'].href + pagingUrlParam)
			.pipe(
				map((r) => {
					let response = <IHALObject>r;
					return response;
				})
			);
	}

	updateLogBookItem(logBookItem: ILogBookItem): Observable<ILogBookItem> {
		return this.http
			.put(logBookItem._links['hb:update'].href, logBookItem)
			.pipe(map((r) => <ILogBookItem>r));
	}

	deleteLogBookItem(logBookItem: ILogBookItem): Observable<any> {
		return this.http.delete(logBookItem._links['hb:delete'].href);
	}

	createServiceRequest(
		machine: IMachine,
		serviceRequest: IServiceRequest
	): Observable<{}> {
		return this.http
			.post(machine._links['hb:createServiceRequest'].href, serviceRequest)
			.pipe(map((r) => r));
	}

	updateReportConfigInternal(reportConfig: IReportUpdation) {
		this.machineReportConfigs.next(reportConfig);
	}

	getReportconfigAsObservable(): Observable<any> {
		return this.machineReportConfigs.asObservable();
	}

	getNiagaraThresholds(machine: IMachine, cardName: string): Observable<any> {
		return this.http
			.get(
				machine._links['hb:niagaraAlertThresholds'].uriTemplate.expand({
					cardName: cardName
				})
			)
			.pipe(
				map((r) => {
					return <IHALObject>r;
				})
			);
	}

	getNiagaraThresholdsByCardNames(
		machine: IMachine,
		cardNames: Array<string>
	): Observable<any> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let niagaraThresholds = [] as Observable<any>[];
				cardNames.forEach((cardName) =>
					niagaraThresholds.push(
						this.http.get(
							machine._links['hb:niagaraAlertThresholds'].uriTemplate.expand({
								cardName: cardName
							})
						)
					)
				);
				return forkJoin(niagaraThresholds).pipe(
					map((response: Array<NiagaraThresholds>) => response),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	setNiagaraThresholds(
		machine: IMachine,
		cardName: string,
		niagaraThreshold: INiagaraThresholds
	): Observable<any> {
		return this.http
			.post(
				machine._links['hb:niagaraAlertThresholds'].uriTemplate.expand({
					cardName: cardName
				}),
				niagaraThreshold
			)
			.pipe(
				map((r) => {
					return r;
				})
			);
	}

	setNiagaraThresholdList(
		machine: IMachine,
		cardNames: Array<string>,
		niagaraThresholdList: Array<INiagaraThresholds>
	): Observable<Array<NiagaraThresholds>> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let niagaraThresholds = [] as Observable<any>[];
				niagaraThresholdList.forEach(
					(niagaraThreshold: INiagaraThresholds, index: number) =>
						niagaraThresholds.push(
							this.http.post(
								machine._links['hb:niagaraAlertThresholds'].uriTemplate.expand({
									cardName: cardNames[index]
								}),
								niagaraThreshold
							)
						)
				);
				return forkJoin(niagaraThresholds).pipe(
					map((response: Array<NiagaraThresholds>) => response),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	getNiagaraAlertConfig(machine: IMachine, cardName: string): Observable<any> {
		return this.http
			.get(
				machine._links['hb:niagaraAlertConfiguration'].uriTemplate.expand({
					cardName: cardName
				})
			)
			.pipe(
				map((r) => {
					return <IHALObject>r;
				})
			);
	}

	getNiagaraAlertsConfig(
		machine: IMachine,
		cardNames: Array<string>
	): Observable<Array<NiagaraAlertConfiguration>> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let niagaraAlertConfigs = [] as Observable<any>[];
				cardNames.forEach((cardName: string) =>
					niagaraAlertConfigs.push(
						this.http.get(
							machine._links['hb:niagaraAlertConfiguration'].uriTemplate.expand(
								{
									cardName: cardName
								}
							)
						)
					)
				);
				return forkJoin(niagaraAlertConfigs).pipe(
					map((response: Array<NiagaraAlertConfiguration>) => response),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	setNiagaraAlertConfig(
		machine: IMachine,
		cardName: string,
		alertConfig: INiagaraAlertConfiguration
	): Observable<any> {
		return this.http
			.post(
				machine._links['hb:niagaraAlertConfiguration'].uriTemplate.expand({
					cardName: cardName
				}),
				alertConfig
			)
			.pipe(
				map((r) => {
					return r;
				})
			);
	}

	setNiagaraAlertsConfig(
		machine: IMachine,
		cardNames: Array<string>,
		alertConfigs: Array<INiagaraAlertConfiguration>
	): Observable<any> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let niagaraAlertConfigs = [] as Observable<any>[];
				alertConfigs.forEach(
					(alertConfig: INiagaraAlertConfiguration, index: number) =>
						niagaraAlertConfigs.push(
							this.http.post(
								machine._links[
									'hb:niagaraAlertConfiguration'
									].uriTemplate.expand({
									cardName: cardNames[index]
								}),
								alertConfig
							)
						)
				);
				return forkJoin(niagaraAlertConfigs).pipe(
					map((response: Array<NiagaraAlertConfiguration>) => response),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	getMachineSensorData(
		machine: IMachine,
		sensorName: string,
		page: IHALPage
	): Observable<ISensorData> {
		const pageQuery = `&size=${page.size}&page=${page.number}&sort=${page.sort}`;
		return this.http
			.get(machine._links['hb:machineSensorData'].href + sensorName + pageQuery)
			.pipe(map((r) => <ISensorData>r));
	}

	getImage(machine: IMachine) {
		return this.http.get(machine._links['hb:image'].href, {
			responseType: 'blob'
		});
	}

	getBrazilianMachineSpecififcation(machine: IMachine): Observable<any> {
		return this.http
			.get(machine._links['hb:brazilMachineSpecification'].href)
			.pipe(
				map((r) => {
					let response = <IHALObject>r;
					return response;
				})
			);
	}

	getSpectrumHistoryTimes(
		metricspecPaths: Array<string>,
		timestampStart: number,
		timestampEnd: number
	): Observable<any> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let historyTimesApis = [] as Observable<any>[];
				metricspecPaths.forEach((metricspecPathValue) =>
					historyTimesApis.push(
						this.http.get(
							api.metrics.hb.historyTimes.uriTemplate.expand({
								metricspecPath: metricspecPathValue,
								timestampStart,
								timestampEnd
							})
						)
					)
				);
				return forkJoin(historyTimesApis).pipe(
					map((response: ISpectrumHistoryTimes[]) => {
						return response.map((result) => {
							if (
								result &&
								result._embedded &&
								result._embedded['hb:timeSeriesResources']
							) {
								return result._embedded['hb:timeSeriesResources'];
							}
							return null;
						});
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	getTimeSeriesAtTimestamp(
		metricspecPaths: Array<string>,
		timestamps: Array<number>
	): Observable<any> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				let timeSeriesApis = [] as Observable<any>[];
				metricspecPaths.forEach((metricspecPathValue, index) =>
					timeSeriesApis.push(
						this.http.get(
							api.metrics.hb.timeSeriesFromTimestamp.uriTemplate.expand({
								metricspecPath: metricspecPathValue,
								timestamp: timestamps[index]
							})
						)
					)
				);
				return forkJoin(timeSeriesApis).pipe(
					map((response: Array<ITimeSeriesAtTimestampResponse>) => {
						return response.map((result) => {
							if (
								result &&
								result._embedded &&
								result._embedded['hb:timeSeriesResources']
							) {
								return result._embedded['hb:timeSeriesResources'];
							}
							return null;
						});
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					})
				);
			})
		);
	}

	getMachineErrorMessages(
		machine: IMachine,
		lang: string,
		activeOnly: boolean
	): Observable<any> {
		return this.http
			.get(
				machine._links['hb:errormessages'].uri +
				`?language=${lang}&activeOnly=${activeOnly}`
			)
			.pipe(
				map((r) => {
					let response = <IHALObject>r;
					return response;
				})
			);
	}

	getMachineErrorMessageValues(
		machine: IMachine,
		lang: string
	): Observable<any> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http.get(
					api.metrics.hb.listErrorMessageValuesOfMachine.uriTemplate.expand({machineId: machine.id, language: lang})
				).pipe(
					map((result) => {
						return <IHALObject>result;
					})
				)}
			)
		);
	}
}
