import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, INotPlacedTableCardDefinition, ISimpleContentCardDefinition, ISpoutTableCardDefinition, IWeightDataTableCardDefinition, } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers, Util } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionHaveradamsService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}


	getSpoutCardDefinition(): ISpoutTableCardDefinition {
		const requiredVariables = Identifiers.bagcounterHaverAdams;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32NetWeigherProdCnt'],
			title: 'A023BagCounterPerFS',
			cardBatchTitle: 'A023BagCounterPerFSBatch',
			unit: 'A027Percent',
			labelPrefix: '',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().bagCounterToday,
		}
	}

	getNotPlacedCardDefinition(): INotPlacedTableCardDefinition {
		const requiredVariables = Identifiers.haverAdamsNotPlacedBagsCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32SpoutPlacedCnt'],
			title: 'A234NotPlacedBags',
			cardBatchTitle: 'A234NotPlacedBags',
			unit: 'A020Count',
			labelPrefix: 'A024FillingStation',
			stackedAreaChartSpec: this.chartConfigService.bagCounterStackedAreaChartConfig(
				Identifiers.haverAdamsNotPlacedBagsCardsStackedChart,
				'u8SpoutCnt',
				'A024FillingStation'
			),
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().notPlacedBags,
		}
	}

	getWrongBagsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32NotPlacedBags', 'u32NotPlacedBagsBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A286WrongBags',
			cardBatchTitle: 'A286WrongBags',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().wrongApplicatedBags,
		}
	}

	getReelChangesCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16ReelChangeCnt', 'u16ReelChangeCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A287ReelChanges',
			cardBatchTitle: 'A287ReelChangesBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().reelChangeToday,
		}
	}

	getSortChangeTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16SortChgDay', 'u16SortChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A249SortChangeToday',
			cardBatchTitle: 'A249SortChangeTodayBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().typeChangesToday,
		}
	}

	getRejectsTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32RejectsDay', 'u32RejectsBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A028RejectionsToday',
			cardBatchTitle: 'A028RejectionsToday',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().rejectsToday,
		}
	}

	getRejectedIncorrectWeightCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16RejectsWrongWeight', 'u16RejectsWrongWeightBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A383IncorrectBags',
			cardBatchTitle: 'A383IncorrectBags',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().rejectedIncorrectWeights,
		}
	}

	getRejectedOpenBagsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16RejectsOpenBag', 'u16RejectsOpenBagBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A382OpenBags',
			cardBatchTitle: 'A382OpenBags',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().rejectedOpenBags,
		}
	}

	getRejectedSampleBagsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16RejectsSampleBag', 'u16RejectsSampleBagBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A381SampleBags',
			cardBatchTitle: 'A381SampleBags',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().rejectedSampleBags,
		}
	}

	getCurrentTypeCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['sCurrentType', 'sCurrentType'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A380TypeCurrent',
			cardBatchTitle: 'A380TypeCurrent',
			unit: '',
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().currentType,
		}
	}

	getProductionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'produktionGutsaeckeSpec',
				0,
				100,
				Identifiers.haverAdamsGoodProductionCards,
				null
			).options,
			title: 'A039GoodWeights',
			unit: 'A027Percent',
			historyVariables: Identifiers.haverAdamsGoodProductionCards[2],
			colorHistoryVariables: Identifiers.haverAdamsGoodProductionCards,
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().goodProduction
		};
	}

	getInterruptsCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.haverAdamInterruptCard.countParams,
			...Identifiers.haverAdamInterruptCard.timeParams
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16StopFilterCnt'],
			title: 'A284Interrupts',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			machineType: '',
			countParams: Identifiers.haverAdamInterruptCard.countParams,
			timeParams: Identifiers.haverAdamInterruptCard.timeParams,
			titleParams: Identifiers.haverAdamInterruptCard.titleParams,
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().interruptionsToday,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.haverAdamInterruptCard.timeParams, Identifiers.haverAdamInterruptCard.countParams, Identifiers.haverAdamInterruptCard.titleParams)
		}
	}

	getProductionTodayCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.haverAdamProdutionTodayCard.countParams,
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32OpenBags'],
			title: 'A015ProductionToday',
			cardBatchTitle: 'A232GoodPerformance',
			unit: 'A020Count',
			machineType: '',
			countParams: Identifiers.haverAdamProdutionTodayCard.countParams,
			timeParams: [],
			titleParams: Identifiers.haverAdamProdutionTodayCard.titleParams,
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().productionToday,
		}
	}

	getWeightFillingStationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationWeightCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32MeanNetScale'],
			netScalePrefix: 's32MeanNetScale',
			netWeightsPrefix: 's32MeanWeights',
			decimals: 's16MeanDecimals',
			title: 'A422WeightData',
			cardBatchTitle: 'A422WeightData',
			unit: 's16MeanUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationWeightCard,
				['u8NetWeighersCnt', 'u8SpoutCnt'],
				this.machine
			),
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().weightData,
		}
	}

	getStandardDeviationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationStandardDeviationCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32StdDevNetScale'],
			netScalePrefix: 's32StdDevNetScale',
			netWeightsPrefix: 's32StdDev',
			decimals: 's16StdDevDecimals',
			title: 'A423StdDevData',
			cardBatchTitle: 'A423StdDevData',
			unit: 's16StdDevUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationStandardDeviationCard,
				['u8NetWeighersCnt', 'u8SpoutCnt'],
				this.machine
			),
			displayVariable: CardDisplayVariables.haverAdamsCardDisplayVariables().stdDevData,
		}
	}
}
