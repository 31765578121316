// Possible MQTT wildcards:
// # (hash character) – multi level wildcard
// + (plus character) -single level wildcard
//

import { IHistoryNeededValues } from "app/charts/definition-model";

// ! Wildcards can only be used to denote a level or multi-levels i.e /house/# and not as part of the name to denote multiple characters e.g. hou# is not valid
export class Util {
	static getDuplicateArray(value: string): Array<string> {
		return [value, value];
	}
	static getHistoryNeededValues(timeParams: Array<Array<string>>, cntParams: Array<Array<string>>, titleParams: Array<string>): Array<IHistoryNeededValues> {
		let returnValues: Array<IHistoryNeededValues> = [];
		for(const [index, timeParam] of timeParams.entries()) {
			returnValues.push({
				id: index + 1,
				neededValue: [...timeParam],
				additionalToolTipInfo: [...cntParams[index]],
				isLineShown: true,
				valueDisplayName: titleParams[index]
			});
		}
		return returnValues;
	}
}

export const Identifiers = {
	get bagcounterHaverAdams(): Array<Array<string>> {
		return [...this.spoutCard, ...this.netWeighter];
	},

	get netWeighter(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8NetWeighersCnt'),
			['u32NetWeigherProdCnt1', 'u32NetWeigherProdCntBatch1'],
			['u32NetWeigherProdCnt2', 'u32NetWeigherProdCntBatch2'],
			['u32NetWeigherProdCnt3', 'u32NetWeigherProdCntBatch3'],
			['u32NetWeigherProdCnt4', 'u32NetWeigherProdCntBatch4'],
			['u32NetWeigherProdCnt5', 'u32NetWeigherProdCntBatch5'],

			['u8NetWeigherPercent1', 'u8NetWeigherPercentBatch1'],
			['u8NetWeigherPercent2', 'u8NetWeigherPercentBatch2'],
			['u8NetWeigherPercent3', 'u8NetWeigherPercentBatch3'],
			['u8NetWeigherPercent4', 'u8NetWeigherPercentBatch4'],
			['u8NetWeigherPercent5', 'u8NetWeigherPercentBatch5']
		];
	},

	get spoutCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u32SpoutProdCnt1', 'u32SpoutProdCntBatch1'],
			['u32SpoutProdCnt2', 'u32SpoutProdCntBatch2'],
			['u32SpoutProdCnt3', 'u32SpoutProdCntBatch3'],
			['u32SpoutProdCnt4', 'u32SpoutProdCntBatch4'],
			['u32SpoutProdCnt5', 'u32SpoutProdCntBatch5'],
			['u32SpoutProdCnt6', 'u32SpoutProdCntBatch6'],
			['u32SpoutProdCnt7', 'u32SpoutProdCntBatch7'],
			['u32SpoutProdCnt8', 'u32SpoutProdCntBatch8'],
			['u32SpoutProdCnt9', 'u32SpoutProdCntBatch9'],
			['u32SpoutProdCnt10', 'u32SpoutProdCntBatch10'],
			['u32SpoutProdCnt11', 'u32SpoutProdCntBatch11'],
			['u32SpoutProdCnt12', 'u32SpoutProdCntBatch12'],
			['u32SpoutProdCnt13', 'u32SpoutProdCntBatch13'],
			['u32SpoutProdCnt14', 'u32SpoutProdCntBatch14'],
			['u32SpoutProdCnt15', 'u32SpoutProdCntBatch15'],
			['u32SpoutProdCnt16', 'u32SpoutProdCntBatch16'],

			['u8SpoutProdPercent1', 'u8SpoutProdPercentBatch1'],
			['u8SpoutProdPercent2', 'u8SpoutProdPercentBatch2'],
			['u8SpoutProdPercent3', 'u8SpoutProdPercentBatch3'],
			['u8SpoutProdPercent4', 'u8SpoutProdPercentBatch4'],
			['u8SpoutProdPercent5', 'u8SpoutProdPercentBatch5'],
			['u8SpoutProdPercent6', 'u8SpoutProdPercentBatch6'],
			['u8SpoutProdPercent7', 'u8SpoutProdPercentBatch7'],
			['u8SpoutProdPercent8', 'u8SpoutProdPercentBatch8'],
			['u8SpoutProdPercent9', 'u8SpoutProdPercentBatch9'],
			['u8SpoutProdPercent10', 'u8SpoutProdPercentBatch10'],
			['u8SpoutProdPercent11', 'u8SpoutProdPercentBatch11'],
			['u8SpoutProdPercent12', 'u8SpoutProdPercentBatch12'],
			['u8SpoutProdPercent13', 'u8SpoutProdPercentBatch13'],
			['u8SpoutProdPercent14', 'u8SpoutProdPercentBatch14'],
			['u8SpoutProdPercent15', 'u8SpoutProdPercentBatch15'],
			['u8SpoutProdPercent16', 'u8SpoutProdPercentBatch16']
		];
	},

	get notPlacedCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u32SpoutPlacedCnt1', 'u32SpoutPlacedCntBatch1'],
			['u32SpoutPlacedCnt2', 'u32SpoutPlacedCntBatch2'],
			['u32SpoutPlacedCnt3', 'u32SpoutPlacedCntBatch3'],
			['u32SpoutPlacedCnt4', 'u32SpoutPlacedCntBatch4'],
			['u32SpoutPlacedCnt5', 'u32SpoutPlacedCntBatch5'],
			['u32SpoutPlacedCnt6', 'u32SpoutPlacedCntBatch6'],
			['u32SpoutPlacedCnt7', 'u32SpoutPlacedCntBatch7'],
			['u32SpoutPlacedCnt8', 'u32SpoutPlacedCntBatch8'],
			['u32SpoutPlacedCnt9', 'u32SpoutPlacedCntBatch9'],
			['u32SpoutPlacedCnt10', 'u32SpoutPlacedCntBatch10'],
			['u32SpoutPlacedCnt11', 'u32SpoutPlacedCntBatch11'],
			['u32SpoutPlacedCnt12', 'u32SpoutPlacedCntBatch12'],
			['u32SpoutPlacedCnt13', 'u32SpoutPlacedCntBatch13'],
			['u32SpoutPlacedCnt14', 'u32SpoutPlacedCntBatch14'],
			['u32SpoutPlacedCnt15', 'u32SpoutPlacedCntBatch15'],
			['u32SpoutPlacedCnt16', 'u32SpoutPlacedCntBatch16'],

			['u8SpoutPlacedPercent1', 'u8SpoutPlacedPercentBatch1'],
			['u8SpoutPlacedPercent2', 'u8SpoutPlacedPercentBatch2'],
			['u8SpoutPlacedPercent3', 'u8SpoutPlacedPercentBatch3'],
			['u8SpoutPlacedPercent4', 'u8SpoutPlacedPercentBatch4'],
			['u8SpoutPlacedPercent5', 'u8SpoutPlacedPercentBatch5'],
			['u8SpoutPlacedPercent6', 'u8SpoutPlacedPercentBatch6'],
			['u8SpoutPlacedPercent7', 'u8SpoutPlacedPercentBatch7'],
			['u8SpoutPlacedPercent8', 'u8SpoutPlacedPercentBatch8'],
			['u8SpoutPlacedPercent9', 'u8SpoutPlacedPercentBatch9'],
			['u8SpoutPlacedPercent10', 'u8SpoutPlacedPercentBatch10'],
			['u8SpoutPlacedPercent11', 'u8SpoutPlacedPercentBatch11'],
			['u8SpoutPlacedPercent12', 'u8SpoutPlacedPercentBatch12'],
			['u8SpoutPlacedPercent13', 'u8SpoutPlacedPercentBatch13'],
			['u8SpoutPlacedPercent14', 'u8SpoutPlacedPercentBatch14'],
			['u8SpoutPlacedPercent15', 'u8SpoutPlacedPercentBatch15'],
			['u8SpoutPlacedPercent16', 'u8SpoutPlacedPercentBatch16']
		];
	},

	get fillingStationWeightCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			Util.getDuplicateArray('u8NetWeighersCnt'),
			Util.getDuplicateArray('s16MeanDecimals'),
			Util.getDuplicateArray('s16MeanUnit'),
			['s32MeanNetScale1', 's32MeanNetScaleBatch1'],
			['s32MeanNetScale2', 's32MeanNetScaleBatch2'],
			['s32MeanNetScale3', 's32MeanNetScaleBatch3'],
			['s32MeanNetScale4', 's32MeanNetScaleBatch4'],
			['s32MeanNetScale5', 's32MeanNetScaleBatch5'],

			['s32MeanWeights1', 's32MeanWeightsBatch1'],
			['s32MeanWeights2', 's32MeanWeightsBatch2'],
			['s32MeanWeights3', 's32MeanWeightsBatch3'],
			['s32MeanWeights4', 's32MeanWeightsBatch4'],
			['s32MeanWeights5', 's32MeanWeightsBatch5'],
			['s32MeanWeights6', 's32MeanWeightsBatch6'],
			['s32MeanWeights7', 's32MeanWeightsBatch7'],
			['s32MeanWeights8', 's32MeanWeightsBatch8'],
			['s32MeanWeights9', 's32MeanWeightsBatch9'],
			['s32MeanWeights10', 's32MeanWeightsBatch10'],
			['s32MeanWeights11', 's32MeanWeightsBatch11'],
			['s32MeanWeights12', 's32MeanWeightsBatch12'],
			['s32MeanWeights13', 's32MeanWeightsBatch13'],
			['s32MeanWeights14', 's32MeanWeightsBatch14'],
			['s32MeanWeights15', 's32MeanWeightsBatch15'],
			['s32MeanWeights16', 's32MeanWeightsBatch16']
		];
	},

	get fillingStationStandardDeviationCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			Util.getDuplicateArray('u8NetWeighersCnt'),
			Util.getDuplicateArray('s16StdDevDecimals'),
			Util.getDuplicateArray('s16StdDevUnit'),
			['s32StdDevNetScale1', 's32StdDevNetScaleBatch1'],
			['s32StdDevNetScale2', 's32StdDevNetScaleBatch2'],
			['s32StdDevNetScale3', 's32StdDevNetScaleBatch3'],
			['s32StdDevNetScale4', 's32StdDevNetScaleBatch4'],
			['s32StdDevNetScale5', 's32StdDevNetScaleBatch5'],

			['s32StdDev1', 's32StdDevBatch1'],
			['s32StdDev2', 's32StdDevBatch2'],
			['s32StdDev3', 's32StdDevBatch3'],
			['s32StdDev4', 's32StdDevBatch4'],
			['s32StdDev5', 's32StdDevBatch5'],
			['s32StdDev6', 's32StdDevBatch6'],
			['s32StdDev7', 's32StdDevBatch7'],
			['s32StdDev8', 's32StdDevBatch8'],
			['s32StdDev9', 's32StdDevBatch9'],
			['s32StdDev10', 's32StdDevBatch10'],
			['s32StdDev11', 's32StdDevBatch11'],
			['s32StdDev12', 's32StdDevBatch12'],
			['s32StdDev13', 's32StdDevBatch13'],
			['s32StdDev14', 's32StdDevBatch14'],
			['s32StdDev15', 's32StdDevBatch15'],
			['s32StdDev16', 's32StdDevBatch16']
		];
	},

	get collectiveFaultsCardSplitted(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16FaultGeneralCnt', 'u16FaultGeneralCntBatch'],
				['u16FaultGeneralSpout1Cnt', 'u16FaultGeneralSpout1CntBatch'],
				['u16FaultGeneralSpout2Cnt', 'u16FaultGeneralSpout2CntBatch'],
				['u16FaultGeneralSpout3Cnt', 'u16FaultGeneralSpout3CntBatch'],
				['u16FaultGeneralSpout4Cnt', 'u16FaultGeneralSpout4CntBatch']
			],
			timeParams: [
				['u16FaultGeneralTime', 'u16FaultGeneralTimeBatch'],
				['u16FaultGeneralSpout1Time', 'u16FaultGeneralSpout1TimeBatch'],
				['u16FaultGeneralSpout2Time', 'u16FaultGeneralSpout2TimeBatch'],
				['u16FaultGeneralSpout3Time', 'u16FaultGeneralSpout3TimeBatch'],
				['u16FaultGeneralSpout4Time', 'u16FaultGeneralSpout4TimeBatch']
			],
			titleParams: [
				'A236Applicator',
				'A024FillingStation{ 1}',
				'A024FillingStation{ 2}',
				'A024FillingStation{ 3}',
				'A024FillingStation{ 4}'
			]
		};
	},

	get collectiveFaultsCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			...this.collectiveFaultsCardSplitted.countParams,
			...this.collectiveFaultsCardSplitted.timeParams
		];

		/* return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u16FaultGeneralCnt', 'u16FaultGeneralCntBatch'],
			['u16FaultGeneralTime', 'u16FaultGeneralTimeBatch'],
			['u16FaultGeneralSpout1Cnt', 'u16FaultGeneralSpout1CntBatch'],
			['u16FaultGeneralSpout2Cnt', 'u16FaultGeneralSpout2CntBatch'],
			['u16FaultGeneralSpout3Cnt', 'u16FaultGeneralSpout3CntBatch'],
			['u16FaultGeneralSpout4Cnt', 'u16FaultGeneralSpout4CntBatch'],
			['u16FaultGeneralSpout1Time', 'u16FaultGeneralSpout1TimeBatch'],
			['u16FaultGeneralSpout2Time', 'u16FaultGeneralSpout2TimeBatch'],
			['u16FaultGeneralSpout3Time', 'u16FaultGeneralSpout3TimeBatch'],
			['u16FaultGeneralSpout4Time', 'u16FaultGeneralSpout4TimeBatch'],
		]; */
	},

	get pressureFaultsCardSplitted(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16PressureApplicatorCnt', 'u16PressureApplicatorCntBatch'],
				['u16PressurePump1Cnt', 'u16PressurePump1CntBatch'],
				['u16PressurePump2Cnt', 'u16PressurePump2CntBatch'],
				['u16PressurePump3Cnt', 'u16PressurePump3CntBatch'],
				['u16PressurePump4Cnt', 'u16PressurePump4CntBatch']
			],
			timeParams: [
				['u16PressureApplicatorTime', 'u16PressureApplicatorTimeBatch'],
				['u16PressurePump1Time', 'u16PressurePump1TimeBatch'],
				['u16PressurePump2Time', 'u16PressurePump2TimeBatch'],
				['u16PressurePump3Time', 'u16PressurePump3TimeBatch'],
				['u16PressurePump4Time', 'u16PressurePump4TimeBatch']
			],
			titleParams: [
				'A236Applicator',
				'A024FillingStation{ 1}',
				'A024FillingStation{ 2}',
				'A024FillingStation{ 3}',
				'A024FillingStation{ 4}'
			]
		};
	},
	get pressureFaultsCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			...this.pressureFaultsCardSplitted.countParams,
			...this.pressureFaultsCardSplitted.timeParams
		];

		/* return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u16PressureApplicatorCnt', 'u16PressureApplicatorCntBatch'],
			['u16PressureApplicatorTime', 'u16PressureApplicatorTimeBatch'],
			['u16PressurePump1Cnt', 'u16PressurePump1CntBatch '],
			['u16PressurePump2Cnt', 'u16PressurePump2CntBatch '],
			['u16PressurePump3Cnt', 'u16PressurePump3CntBatch '],
			['u16PressurePump4Cnt', 'u16PressurePump4CntBatch '],
			['u16PressurePump1Time', 'u16PressurePump1TimeBatch '],
			['u16PressurePump2Time', 'u16PressurePump2TimeBatch '],
			['u16PressurePump3Time', 'u16PressurePump3TimeBatch '],
			['u16PressurePump4Time', 'u16PressurePump4TimeBatch ']
		]; */
	},
	get maintenanceCard() {
		return {
			currentValues: [
				'u32MaintCurr0',
				'u32MaintCurr1',
				'u32MaintCurr2',
				'u32MaintCurr3',
				'u32MaintCurr4',
				'u32MaintCurr5',
				'u32MaintCurr6',
				'u32MaintCurr7',
				'u32MaintCurr8',
				'u32MaintCurr9',
				'u32MaintCurr10',
				'u32MaintCurr11',
				'u32MaintCurr12',
				'u32MaintCurr13',
				'u32MaintCurr14'
			],
			minValues: [
				'u32MaintMin0',
				'u32MaintMin1',
				'u32MaintMin2',
				'u32MaintMin3',
				'u32MaintMin4',
				'u32MaintMin5',
				'u32MaintMin6',
				'u32MaintMin7',
				'u32MaintMin8',
				'u32MaintMin9',
				'u32MaintMin10',
				'u32MaintMin11',
				'u32MaintMin12',
				'u32MaintMin13',
				'u32MaintMin14'
			],
			maxValues: [
				'u32MaintMax0',
				'u32MaintMax1',
				'u32MaintMax2',
				'u32MaintMax3',
				'u32MaintMax4',
				'u32MaintMax5',
				'u32MaintMax6',
				'u32MaintMax7',
				'u32MaintMax8',
				'u32MaintMax9',
				'u32MaintMax10',
				'u32MaintMax11',
				'u32MaintMax12',
				'u32MaintMax13',
				'u32MaintMax14'
			],
			cardNames: [
				'sMaintName0',
				'sMaintName1',
				'sMaintName2',
				'sMaintName3',
				'sMaintName4',
				'sMaintName5',
				'sMaintName6',
				'sMaintName7',
				'sMaintName8',
				'sMaintName9',
				'sMaintName10',
				'sMaintName11',
				'sMaintName12',
				'sMaintName13',
				'sMaintName14'
			],
			resetStates: [
				'u8MaintResetState0',
				'u8MaintResetState1',
				'u8MaintResetState2',
				'u8MaintResetState3',
				'u8MaintResetState4',
				'u8MaintResetState5',
				'u8MaintResetState6',
				'u8MaintResetState7',
				'u8MaintResetState8',
				'u8MaintResetState9',
				'u8MaintResetState10',
				'u8MaintResetState11',
				'u8MaintResetState12',
				'u8MaintResetState13',
				'u8MaintResetState14'
			],
			units: [
				'sMaintUnit0',
				'sMaintUnit1',
				'sMaintUnit2',
				'sMaintUnit3',
				'sMaintUnit4',
				'sMaintUnit5',
				'sMaintUnit6',
				'sMaintUnit7',
				'sMaintUnit8',
				'sMaintUnit9',
				'sMaintUnit10',
				'sMaintUnit11',
				'sMaintUnit12',
				'sMaintUnit13',
				'sMaintUnit14'
			]
		};
	},

	get containerCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8BinCnt'),
			['u32BinProdCnt1', 'u32BinProdCntBatch1'],
			['u32BinProdCnt2', 'u32BinProdCntBatch2'],
			['u32BinProdCnt3', 'u32BinProdCntBatch3'],
			['u32BinProdCnt4', 'u32BinProdCntBatch4'],
			['u32BinProdCnt5', 'u32BinProdCntBatch5'],
			['u32BinProdCnt6', 'u32BinProdCntBatch6'],
			['u32BinProdCnt7', 'u32BinProdCntBatch7'],
			['u32BinProdCnt8', 'u32BinProdCntBatch8'],
			['u32BinProdCnt9', 'u32BinProdCntBatch9'],
			['u32BinProdCnt10', 'u32BinProdCntBatch10'],
			['u8BinProdPercent1', 'u8BinProdPercentBatch1'],
			['u8BinProdPercent2', 'u8BinProdPercentBatch2'],
			['u8BinProdPercent3', 'u8BinProdPercentBatch3'],
			['u8BinProdPercent4', 'u8BinProdPercentBatch4'],
			['u8BinProdPercent5', 'u8BinProdPercentBatch5'],
			['u8BinProdPercent6', 'u8BinProdPercentBatch6'],
			['u8BinProdPercent7', 'u8BinProdPercentBatch7'],
			['u8BinProdPercent8', 'u8BinProdPercentBatch8'],
			['u8BinProdPercent9', 'u8BinProdPercentBatch9'],
			['u8BinProdPercent10', 'u8BinProdPercentBatch10']
		];
	},

	get internalPressureCard() {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u32BagPressure1', 'u32BagPressure1Batch'],
			['u32BagPressure2', 'u32BagPressure2Batch'],
			['u32BagPressure3', 'u32BagPressure3Batch'],
			['u32BagPressure4', 'u32BagPressure4Batch'],
			['u32MaxBagPressure1', 'u32MaxBagPressure1Batch'],
			['u32MaxBagPressure2', 'u32MaxBagPressure2Batch'],
			['u32MaxBagPressure3', 'u32MaxBagPressure3Batch'],
			['u32MaxBagPressure4', 'u32MaxBagPressure4Batch']
		];
	},
	get energyMeasureCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u32EffectivePower'),
			Util.getDuplicateArray('u32ReactivePower'),
			Util.getDuplicateArray('u32ApparentPower'),
			Util.getDuplicateArray('u32AirFlowRate'),
			['u32AktiveEnergy', 'u32AktiveEnergyBatch'],
			['u32AirVolume', 'u32AirVolumeBatch']
		];
	},
	get dynamicCards() {
		return [
			'aDynCards0',
			'aDynCards1',
			'aDynCards2',
			'aDynCards3',
			'aDynCards4',
			'aDynCards5',
			'aDynCards6',
			'aDynCards7',
			'aDynCards8',
			'aDynCards9',
			'aDynCards10',
			'aDynCards11',
			'aDynCards12',
			'aDynCards13',
			'aDynCards14',
			'aDynCards15',
			'aDynCards16',
			'aDynCards17',
			'aDynCards18',
			'aDynCards19',
			'aDynCards20',
			'aDynCards21',
			'aDynCards22',
			'aDynCards23',
			'aDynCards24',
			'aDynCards25',
			'aDynCards26',
			'aDynCards27',
			'aDynCards28',
			'aDynCards29',
			'aDynCards30',
			'aDynCards31',
			'aDynCards32',
			'aDynCards33',
			'aDynCards34',
			'aDynCards35',
			'aDynCards36',
			'aDynCards37',
			'aDynCards38',
			'aDynCards39',
			'aDynCards40',
			'aDynCards41',
			'aDynCards42',
			'aDynCards43',
			'aDynCards44',
			'aDynCards45',
			'aDynCards46',
			'aDynCards47',
			'aDynCards48',
			'aDynCards49',
			'aDynCards50',
			'aDynCards51',
			'aDynCards52',
			'aDynCards53',
			'aDynCards54',
			'aDynCards55',
			'aDynCards56',
			'aDynCards57',
			'aDynCards58',
			'aDynCards59',
			'aDynCards60',
			'aDynCards61',
			'aDynCards62',
			'aDynCards63',
			'aDynCards64',
			'aDynCards65',
			'aDynCards66',
			'aDynCards67',
			'aDynCards68',
			'aDynCards69',
			'aDynCards70',
			'aDynCards71',
			'aDynCards72',
			'aDynCards73',
			'aDynCards74',
			'aDynCards75',
			'aDynCards76',
			'aDynCards77',
			'aDynCards78',
			'aDynCards79',
			'aDynCards80',
			'aDynCards81',
			'aDynCards82',
			'aDynCards83',
			'aDynCards84',
			'aDynCards85',
			'aDynCards86',
			'aDynCards87',
			'aDynCards88',
			'aDynCards89',
			'aDynCards90',
			'aDynCards91',
			'aDynCards92',
			'aDynCards93',
			'aDynCards94',
			'aDynCards95',
			'aDynCards96',
			'aDynCards97',
			'aDynCards98',
			'aDynCards99'
		];
	},
	get egfInterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16NoReleaseCnt', 'u16NoReleaseCntBatch'],
				['u16FaultEgfCnt', 'u16FaultEgfCntBatch'],
				['u16FltPalTranCnt', 'u16FltPalTranCntBatch'],
				['u16FaultAirPressCnt', 'u16FaultAirPressCntBatch'],
				['u16SafetyCircuitCnt', 'u16SafetyCircuitCntBatch']
			],
			timeParams: [
				['u16NoReleaseTime', 'u16NoReleaseTimeBatch'],
				['u16FaultEgfTime', 'u16FaultEgfTimeBatch'],
				['u16FltPalTranTime', 'u16FltPalTranTimeBatch'],
				['u16FaultAirPressTime', 'u16FaultAirPressTimeBatch'],
				['u16SafetyCircuitTime', 'u16SafetyCircuitTimeBatch']
			],
			titleParams: [
				'A292FillingRelease',
				'A293EGF',
				'A294PalletTrasport',
				'A034CompAirFault',
				'A295SafetyCircuit'
			]
		};
	},

	get egfGoodProductionCards(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			['u8ProdGoodPercent', 'u8ProdGoodPercentBatch'],
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get evpInterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16StopFilterCnt', 'u16StopFilterCntBatch'],
				['u16StopRotMotCnt', 'u16StopRotMotCntBatch'],
				['u16StopProdFeedCnt', 'u16StopProdFeedCntBatch'],
				['u16FaultCheckweigherCnt', 'u16FaultCheckweigherCntBatch'],
				['u16StopPalletizerCnt', 'u16StopPalletizerCntBatch'],
				['u16FaultAirPressCnt', 'u16FaultAirPressCntBatch']
				// ['u16SortChgCnt', 'u16SortChgCntBatch'],
			],
			timeParams: [
				['u16StopFilterTime', 'u16StopFilterTimeBatch'],
				['u16StopRotMotTime', 'u16StopRotMotTimeBatch'],
				['u16StopProdFeedTime', 'u16StopProdFeedTimeBatch'],
				['u16FaultCheckweigherTime', 'u16FaultCheckweigherTimeBatch'],
				['u16StopPalletizerTime', 'u16StopPalletizerTimeBatch'],
				['u16FaultAirPressTime', 'u16FaultAirPressTimeBatch']
				// ['u16SortChgTime', 'u16SortChgTimeBatch']
			],
			titleParams: [
				'A029FilterStop',
				'A252IVTSTOP',
				'A031ProductFeedStop',
				'A242FaultCheckWeigher',
				'A033PalletizerStop',
				'A034CompAirFault'
				// 'A035TypeChange'
			]
		};
	},

	get ivtBagCounterTodayCards(): Array<Array<string>> {
		return [
			['u8SpoutProdPercent1', 'u8SpoutProdPercentBatch1'],
			['u8SpoutProdPercent2', 'u8SpoutProdPercentBatch2'],
			['u8SpoutProdPercent3', 'u8SpoutProdPercentBatch3'],
			['u8SpoutProdPercent4', 'u8SpoutProdPercentBatch4'],
			['u8SpoutProdPercent5', 'u8SpoutProdPercentBatch5'],
			['u8SpoutProdPercent6', 'u8SpoutProdPercentBatch6'],
			['u8SpoutProdPercent7', 'u8SpoutProdPercentBatch7'],
			['u8SpoutProdPercent8', 'u8SpoutProdPercentBatch8'],
			['u8SpoutProdPercent9', 'u8SpoutProdPercentBatch9'],
			['u8SpoutProdPercent10', 'u8SpoutProdPercentBatch10'],
			['u8SpoutProdPercent11', 'u8SpoutProdPercentBatch11'],
			['u8SpoutProdPercent12', 'u8SpoutProdPercentBatch12'],
			['u8SpoutProdPercent13', 'u8SpoutProdPercentBatch13'],
			['u8SpoutProdPercent14', 'u8SpoutProdPercentBatch14'],
			['u8SpoutProdPercent15', 'u8SpoutProdPercentBatch15'],
			['u8SpoutProdPercent16', 'u8SpoutProdPercentBatch16']
		];
	},

	get ivtNotPlacedBagsCards(): Array<Array<string>> {
		return [
			['u32SpoutPlacedCnt1', 'u32SpoutPlacedCntBatch1'],
			['u32SpoutPlacedCnt2', 'u32SpoutPlacedCntBatch2'],
			['u32SpoutPlacedCnt3', 'u32SpoutPlacedCntBatch3'],
			['u32SpoutPlacedCnt4', 'u32SpoutPlacedCntBatch4'],
			['u32SpoutPlacedCnt5', 'u32SpoutPlacedCntBatch5'],
			['u32SpoutPlacedCnt6', 'u32SpoutPlacedCntBatch6'],
			['u32SpoutPlacedCnt7', 'u32SpoutPlacedCntBatch7'],
			['u32SpoutPlacedCnt8', 'u32SpoutPlacedCntBatch8'],
			['u32SpoutPlacedCnt9', 'u32SpoutPlacedCntBatch9'],
			['u32SpoutPlacedCnt10', 'u32SpoutPlacedCntBatch10'],
			['u32SpoutPlacedCnt11', 'u32SpoutPlacedCntBatch11'],
			['u32SpoutPlacedCnt12', 'u32SpoutPlacedCntBatch12'],
			['u32SpoutPlacedCnt13', 'u32SpoutPlacedCntBatch13'],
			['u32SpoutPlacedCnt14', 'u32SpoutPlacedCntBatch14'],
			['u32SpoutPlacedCnt15', 'u32SpoutPlacedCntBatch15'],
			['u32SpoutPlacedCnt16', 'u32SpoutPlacedCntBatch16']
		];
	},

	get evpGoodProductionCards(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			['u8ProdGoodPercent', 'u8ProdGoodPercentBatch'],
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get evpBagCounterTodayCards(): Array<Array<string>> {
		return [
			['u8SpoutProdPercent1', 'u8SpoutProdPercentBatch1'],
			['u8SpoutProdPercent2', 'u8SpoutProdPercentBatch2'],
			['u8SpoutProdPercent3', 'u8SpoutProdPercentBatch3'],
			['u8SpoutProdPercent4', 'u8SpoutProdPercentBatch4'],
			['u8SpoutProdPercent5', 'u8SpoutProdPercentBatch5'],
			['u8SpoutProdPercent6', 'u8SpoutProdPercentBatch6'],
			['u8SpoutProdPercent7', 'u8SpoutProdPercentBatch7'],
			['u8SpoutProdPercent8', 'u8SpoutProdPercentBatch8'],
			['u8SpoutProdPercent9', 'u8SpoutProdPercentBatch9'],
			['u8SpoutProdPercent10', 'u8SpoutProdPercentBatch10'],
			['u8SpoutProdPercent11', 'u8SpoutProdPercentBatch11'],
			['u8SpoutProdPercent12', 'u8SpoutProdPercentBatch12'],
			['u8SpoutProdPercent13', 'u8SpoutProdPercentBatch13'],
			['u8SpoutProdPercent14', 'u8SpoutProdPercentBatch14'],
			['u8SpoutProdPercent15', 'u8SpoutProdPercentBatch15'],
			['u8SpoutProdPercent16', 'u8SpoutProdPercentBatch16']
		];
	},

	get feigeInterruptCard() {
		return {
			countParams: [
				Util.getDuplicateArray('u16FaultCenteringCnt'),
				Util.getDuplicateArray('u16FaultN2StationCnt'),
				Util.getDuplicateArray('u16FaultFSCnt'),
				Util.getDuplicateArray('u16FaultClosingCnt')
			],
			timeParams: [
				Util.getDuplicateArray('u16FaultCenteringTime'),
				Util.getDuplicateArray('u16FaultN2StationTime'),
				Util.getDuplicateArray('u16FaultFSTime'),
				Util.getDuplicateArray('u16FaultClosingTime')
			],
			titleParams: [
				'A118CentreStation',
				'A119N2Station',
				'A120FillingStation',
				'A121SealingStation'
			]
		};
	},
	get feigeCycleTimeCard(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('u16GeneralTime'),
				Util.getDuplicateArray('u16CenteringTime'),
				Util.getDuplicateArray('u16N2StationTime'),
				Util.getDuplicateArray('u16FSTime'),
				Util.getDuplicateArray('u16ClosingTime')
			],
			titleParams: [
				'A117Total',
				'A118CentreStation',
				'A119N2Station',
				'A120FillingStation',
				'A121SealingStation'
			]
		};
	},

	get fiegeFillingCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16MachPerf_R_Y'),
			Util.getDuplicateArray('u16MachPerf_Y_G'),
			Util.getDuplicateArray('u16MachPerf'),
			Util.getDuplicateArray('u16MachPerf_Max')
		];
	},

	get fiegeMaintenanceCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			Util.getDuplicateArray('u32MaintGenCurr'),
			Util.getDuplicateArray('u32MaintGen')
		];
	},

	get haverAdamProdutionTodayCard(): {
		countParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u32ProdDay', 'u32ProdBatch'],
				['u32NotPlacedBags', 'u32NotPlacedBagsBatch'],
				['u32RejectsDay', 'u32RejectsBatch'],
				['u32WrongWeight', 'u32WrongWeightBatch'],
				['u32OpenBags', 'u32OpenBagsBatch']
			],
			titleParams: [
				'A017RemProduction',
				'A286WrongBags',
				'A028RejectionsToday',
				'A238IncorrectWeight',
				'A241OpenBags'
			]
		};
	},

	get haverAdamsNotPlacedBagsCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u32SpoutPlacedCnt1', 'u32SpoutPlacedCntBatch1'],
			['u32SpoutPlacedCnt2', 'u32SpoutPlacedCntBatch2'],
			['u32SpoutPlacedCnt3', 'u32SpoutPlacedCntBatch3'],
			['u32SpoutPlacedCnt4', 'u32SpoutPlacedCntBatch4'],
			['u32SpoutPlacedCnt5', 'u32SpoutPlacedCntBatch5'],
			['u32SpoutPlacedCnt6', 'u32SpoutPlacedCntBatch6'],
			['u32SpoutPlacedCnt7', 'u32SpoutPlacedCntBatch7'],
			['u32SpoutPlacedCnt8', 'u32SpoutPlacedCntBatch8'],
			['u32SpoutPlacedCnt9', 'u32SpoutPlacedCntBatch9'],
			['u32SpoutPlacedCnt10', 'u32SpoutPlacedCntBatch10'],
			['u32SpoutPlacedCnt11', 'u32SpoutPlacedCntBatch11'],
			['u32SpoutPlacedCnt12', 'u32SpoutPlacedCntBatch12'],
			['u32SpoutPlacedCnt13', 'u32SpoutPlacedCntBatch13'],
			['u32SpoutPlacedCnt14', 'u32SpoutPlacedCntBatch14'],
			['u32SpoutPlacedCnt15', 'u32SpoutPlacedCntBatch15'],
			['u32SpoutPlacedCnt16', 'u32SpoutPlacedCntBatch16'],

			['u8SpoutPlacedPercent1', 'u8SpoutPlacedPercentBatch1'],
			['u8SpoutPlacedPercent2', 'u8SpoutPlacedPercentBatch2'],
			['u8SpoutPlacedPercent3', 'u8SpoutPlacedPercentBatch3'],
			['u8SpoutPlacedPercent4', 'u8SpoutPlacedPercentBatch4'],
			['u8SpoutPlacedPercent5', 'u8SpoutPlacedPercentBatch5'],
			['u8SpoutPlacedPercent6', 'u8SpoutPlacedPercentBatch6'],
			['u8SpoutPlacedPercent7', 'u8SpoutPlacedPercentBatch7'],
			['u8SpoutPlacedPercent8', 'u8SpoutPlacedPercentBatch8'],
			['u8SpoutPlacedPercent9', 'u8SpoutPlacedPercentBatch9'],
			['u8SpoutPlacedPercent10', 'u8SpoutPlacedPercentBatch10'],
			['u8SpoutPlacedPercent11', 'u8SpoutPlacedPercentBatch11'],
			['u8SpoutPlacedPercent12', 'u8SpoutPlacedPercentBatch12'],
			['u8SpoutPlacedPercent13', 'u8SpoutPlacedPercentBatch13'],
			['u8SpoutPlacedPercent14', 'u8SpoutPlacedPercentBatch14'],
			['u8SpoutPlacedPercent15', 'u8SpoutPlacedPercentBatch15'],
			['u8SpoutPlacedPercent16', 'u8SpoutPlacedPercentBatch16']
		];
	},

	get haverAdamsNotPlacedBagsCardsStackedChart(): Array<Array<string>> {
		return this.haverAdamsNotPlacedBagsCard.slice(1, 11);
	},

	get haverAdamInterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16StopProdFeedCnt', 'u16StopProdFeedCntBatch'],
				['u16FltBagManufactureCnt', 'u16FltBagManufactureCntBatch'],
				['u16FltWeigherCnt', 'u16FltWeigherCntBatch'],
				['u16FltFilledBagTransCnt', 'u16FltFilledBagTransCntBatch'],
				['u16FltConveyorSysCnt', 'u16FltConveyorSysCntBatch'],
				['u16StopPalletizerCnt', 'u16StopPalletizerCntBatch'],
				['u16StopFilterCnt', 'u16StopFilterCntBatch'],
				['u16FltAirPressCnt', 'u16FltAirPressCntBatch'],
				// ['u16SortChgDay', 'u16SortChgBatch'],
				['u16ReelChangeCnt', 'u16ReelChangeCntBatch']
			],
			timeParams: [
				['u16StopProdFeedTime', 'u16StopProdFeedTimeBatch'],
				['u16FltBagManufactureTime', 'u16FltBagManufactureTimeBatch'],
				['u16FltWeigherTime', 'u16FltWeigherTimeBatch'],
				['u16FltFilledBagTransTime', 'u16FltFilledBagTransTimeBatch'],
				['u16FltConveyorSysTime', 'u16FltConveyorSysTimeBatch'],
				['u16StopPalletizerTime', 'u16StopPalletizerTimeBatch'],
				['u16StopFilterTime', 'u16StopFilterTimeBatch'],
				['u16FltAirPressTime', 'u16FltAirPressTimeBatch'],
				// ['u16SortChgTime', 'u16SortChgTimeBatch'],
				['u16ReelChangeTime', 'u16ReelChangeTimeBatch']
			],
			titleParams: [
				'A031ProductFeedStop',
				'A220FaultBagManufacture',
				'A224FaultWeigher',
				'A390FilledBagTrans',
				'A400ConveyorSystem',
				'A033PalletizerStop',
				'A029FilterStop',
				'A034CompAirFault',
				// 'A035TypeChange',
				'A287ReelChanges'
			]
		};
	},

	get haverAdamsGoodProductionCards(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			['u8ProdGoodPercent', 'u8ProdGoodPercentBatch'],
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get haverAdamsBagCounterCards(): Array<Array<string>> {
		return [
			['u8NetWeigherPercent1', 'u8NetWeigherPercentBatch1'],
			['u8NetWeigherPercent2', 'u8NetWeigherPercentBatch2'],

			['u8SpoutProdPercent1', 'u8SpoutProdPercentBatch1'],
			['u8SpoutProdPercent2', 'u8SpoutProdPercentBatch2'],
			['u8SpoutProdPercent3', 'u8SpoutProdPercentBatch3'],
			['u8SpoutProdPercent4', 'u8SpoutProdPercentBatch4'],
			['u8SpoutProdPercent5', 'u8SpoutProdPercentBatch5'],
			['u8SpoutProdPercent6', 'u8SpoutProdPercentBatch6'],
			['u8SpoutProdPercent7', 'u8SpoutProdPercentBatch7'],
			['u8SpoutProdPercent8', 'u8SpoutProdPercentBatch8'],
			['u8SpoutProdPercent9', 'u8SpoutProdPercentBatch9'],
			['u8SpoutProdPercent10', 'u8SpoutProdPercentBatch10']
		];
	},

	get integraProductionBagCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16ProdGoodPerf_R_Y'),
			Util.getDuplicateArray('u16ProdGoodPerf_Y_G'),
			['u16ProdGoodPerf', 'u16ProdGoodPerfBatch'],
			Util.getDuplicateArray('u16ProdGoodPerf_Max')
		];
	},

	get integraBagQualityCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u32CorrPlacedBagCnt', 'u32CorrPlacedBagCntBatch'],
				['u32SecondAttemptOpenCnt', 'u32SecondAttemptOpenCntBatch'],
				['u32ThirdAttemptOpenCnt', 'u32ThirdAttemptOpenCntBatch'],
				['u32DiscardedBagsCnt', 'u32DiscardedBagsCntBatch']
			],
			timeParams: [
				['u16CorrPlacedBagPercent', 'u16CorrPlacedBagPercentBatch'],
				['u16SecondAttemptOpenPercent', 'u16SecondAttemptOpenPercentBatch'],
				['u16ThirdAttemptOpenPercent', 'u16ThirdAttemptOpenPercentBatch'],
				['u16DiscardedBagsPercent', 'u16DiscardedBagsPercentBatch']
			],
			titleParams: [
				'A132CorrPlacedbags',
				'A133TwoAttempts',
				'A134ThreeAttempts',
				'A135DiscardeBags'
			]
		};
	},
	get integraInterruptCard(): {
		countParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16FaultAirPressCnt', 'u16FaultAirPressCntBatch'],
				['u16FaultProductFeedCnt', 'u16FaultProductFeedCntBatch'],
				['u16FaultEmptyBagMagazinCnt', 'u16FaultEmptyBagMagazinCntBatch'],
				['u16FaultSeparationCnt', 'u16FaultSeparationCntBatch'],
				['u16FaultVacuumCnt', 'u16FaultVacuumCntBatch'],
				['u16FaultFillingSpoutCnt', 'u16FaultFillingSpoutCntBatch'],
				['u16FaultFullBagTransportCnt', 'u16FaultFullBagTransportCntBatch'],
				['u16FaultBagSealingCnt', 'u16FaultBagSealingCntBatch'],
				['u16FaultEmergencyStopCnt', 'u16FaultEmergencyStopCntBatch']
			],
			titleParams: [
				'A137CompAir',
				'A138ProductFeed',
				'A139EmptyBagMag',
				'A140Separation',
				'A141Vacuum',
				'A142FillingStation',
				'A143FullBagTransport',
				'A144BagSealing',
				'A145EmergencyStop'
			]
		};
	},

	get isfProductionTodayCard(): {
		countParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u32ProdDay', 'u32ProdBatch'],
				['u32NotPlacedBags', 'u32NotPlacedBagsBatch'],
				['u32RejectsDay', 'u32RejectsBatch'],
				['u32WrongWeight', 'u32WrongWeightBatch'],
				['u32OpenBags', 'u32OpenBagsBatch']
			],
			titleParams: [
				'A017RemProduction',
				'A286WrongBags',
				'A028RejectionsToday',
				'A383IncorrectBags',
				'A382OpenBags'
			]
		};
	},

	get isfInterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16FaultTotalCnt', 'u16FaultTotalCntBatch'],
				['u16FaultBagManufactureCnt', 'u16FaultBagManufactureCntBatch'],
				['u16FaultBagFillingCnt', 'u16FaultBagFillingCntBatch'],
				['u16FaultBagTransportCnt', 'u16FaultBagTransportCntBatch'],
				['u16FaultSealingDeviceCnt', 'u16FaultSealingDeviceCntBatch'],
				['u16NetGrossWeigherCnt', 'u16NetGrossWeigherCntBatch'],
				['u16FaultOtherPeripheryCnt', 'u16FaultOtherPeripheryCntBatch']
			],
			timeParams: [
				['u16FaultTotalTime', 'u16FaultTotalTimeBatch'],
				['u16FaultBagManufactureTime', 'u16FaultBagManufactureTimeBatch'],
				['u16FaultBagFillingTime', 'u16FaultBagFillingTimeBatch'],
				['u16FaultBagTransportTime', 'u16FaultBagTransportTimeBatch'],
				['u16FaultSealingDeviceTime', 'u16FaultSealingDeviceTimeBatch'],
				['u16NetGrossWeigherTime', 'u16NetGrossWeigherTimeBatch'],
				['u16FaultOtherPeripheryTime', 'u16FaultOtherPeripheryTimeBatch']
			],
			titleParams: [
				'A117Total',
				'A220FaultBagManufacture',
				'A221FaultBagFilling',
				'A222FaultBagTransport',
				'A223FaultSealing',
				'A224FaultWeigher',
				'A225FaultPeriphery'
			]
		};
	},

	get isf1BagcounterCard(): Array<Array<string>> {
		return [
			...this.isf1BagCounterNetWeighterCount,
			...this.isf1BagCounterNetWeighterPercent,
			...this.isf1BagCounterFillingStation
		];
	},

	get isf1BagCounterNetWeighterCount(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8NetWeighersCnt'),
			['u32NetWeigherProdCnt1', 'u32NetWeigherProdCntBatch1'],
			['u32NetWeigherProdCnt2', 'u32NetWeigherProdCntBatch2'],
			['u32NetWeigherProdCnt3', 'u32NetWeigherProdCntBatch3'],
			['u32NetWeigherProdCnt4', 'u32NetWeigherProdCntBatch4'],
			['u32NetWeigherProdCnt5', 'u32NetWeigherProdCntBatch5']
		];
	},

	get isf1BagCounterNetWeighterPercent(): Array<Array<string>> {
		return [
			['u8NetWeigherPercent1', 'u8NetWeigherPercentBatch1'],
			['u8NetWeigherPercent2', 'u8NetWeigherPercentBatch2'],
			['u8NetWeigherPercent3', 'u8NetWeigherPercentBatch3'],
			['u8NetWeigherPercent4', 'u8NetWeigherPercentBatch4'],
			['u8NetWeigherPercent5', 'u8NetWeigherPercentBatch5']
		];
	},

	get isf1BagCounterFillingStation(): Array<Array<string>> {
		return [['u32SpoutProdCnt1', 'u32SpoutProdCntBatch1']];
	},

	get isf1CurrentPerformanceCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16ProdGoodPerf_R_Y'),
			Util.getDuplicateArray('u16ProdGoodPerf_Y_G'),
			Util.getDuplicateArray('u16ProdGoodPerf'),
			Util.getDuplicateArray('u16ProdGoodPerf_Max')
		];
	},

	get isf1GoodProductionCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			['u8ProdGoodPercent', 'u8ProdGoodPercentBatch'],
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get ivtInterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16StopFilterCnt', 'u16StopFilterCntBatch'],
				['u16StopRotMotCnt', 'u16StopRotMotCntBatch'],
				['u16StopProdFeedCnt', 'u16StopProdFeedCntBatch'],
				['u16FaultCheckweigherCnt', 'u16FaultCheckweigherCntBatch'],
				['u16StopPalletizerCnt', 'u16StopPalletizerCntBatch'],
				['u16FaultAirPressCnt', 'u16FaultAirPressCntBatch']
				// ['u16SortChgCnt', 'u16SortChgCntBatch'],
			],
			timeParams: [
				['u16StopFilterTime', 'u16StopFilterTimeBatch'],
				['u16StopRotMotTime', 'u16StopRotMotTimeBatch'],
				['u16StopProdFeedTime', 'u16StopProdFeedTimeBatch'],
				['u16FaultCheckweigherTime', 'u16FaultCheckweigherTimeBatch'],
				['u16StopPalletizerTime', 'u16StopPalletizerTimeBatch'],
				['u16FaultAirPressTime', 'u16FaultAirPressTimeBatch']
				// ['u16SortChgTime', 'u16SortChgTimeBatch'],
			],
			titleParams: [
				'A029FilterStop',
				'A252IVTSTOP',
				'A031ProductFeedStop',
				'A242FaultCheckWeigher',
				'A033PalletizerStop',
				'A034CompAirFault'
				// 'A035TypeChange'
			]
		};
	},

	get ivtGoodProductionCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			['u8ProdGoodPercent', 'u8ProdGoodPercentBatch'],
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get miniAdamsInterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16FaultOpReleaseCnt', 'u16FaultOpReleaseCntBatch'],
				['u16FaultProdFeedCnt', 'u16FaultProdFeedCntBatch'],
				['u16FaultAuxDriveCnt', 'u16FaultAuxDriveCntBatch'],
				['u16FaultConsumableCnt', 'u16FaultConsumableCntBatch'],
				['u16FaultDischargeCnt', 'u16FaultDischargeCntBatch']
				// ['u16SortChgCnt', 'u16SortChgCntBatch']
			],
			timeParams: [
				['u16FaultOpReleaseTime', 'u16FaultOpReleaseTimeBatch'],
				['u16FaultProdFeedTime', 'u16FaultProdFeedTimeBatch'],
				['u16FaultAuxDriveTime', 'u16FaultAuxDriveTimeBatch'],
				['u16FaultConsumableTime', 'u16FaultConsumableTimeBatch'],
				['u16FaultDischargeTime', 'u16FaultDischargeTimeBatch']
				// ['u16SortChgTime', 'u16SortChgTimeBatch']
			],
			titleParams: [
				'A088OpRelease',
				'A089ProdFeedStop',
				'A090AuxDrive',
				'A091Consumables',
				'A092DischLineStop'
				// 'A093TypeChange'
			]
		};
	},

	get miniadamsSpeedRelatedProductionCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16PerfByRotationCnt_R_Y'),
			Util.getDuplicateArray('u16PerfByRotationCnt_Y_G'),
			Util.getDuplicateArray('u16PerfByRotationCntCurr'),
			Util.getDuplicateArray('u16PerfByRotationCnt')
		];
	},

	get miniadamsProductionGoodCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			Util.getDuplicateArray('u8ProdGoodPercent'),
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get miniadamsContainerCard(): Array<Array<string>> {
		return [
			['u32BinProdCnt1', 'u32BinProdCntBatch1'],
			['u32BinProdCnt2', 'u32BinProdCntBatch2'],
			['u32BinProdCnt3', 'u32BinProdCntBatch3'],
			['u32BinProdCnt4', 'u32BinProdCntBatch4'],
			['u32BinProdCnt5', 'u32BinProdCntBatch5'],
			['u32BinProdCnt6', 'u32BinProdCntBatch6'],
			['u32BinProdCnt7', 'u32BinProdCntBatch7'],
			['u32BinProdCnt8', 'u32BinProdCntBatch8'],
			['u32BinProdCnt9', 'u32BinProdCntBatch9'],
			['u32BinProdCnt10', 'u32BinProdCntBatch10']
		];
	},

	get neutraleInterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16StopFilterCnt', 'u16StopFilterCntBatch'],
				['u16StopProdFeedCnt', 'u16StopProdFeedCntBatch'],
				['u16FltFillingCnt', 'u16FltFillingCntBatch'],
				['u16FltWeigherCnt', 'u16FltWeigherCntBatch'],
				['u16FltPerepheryCnt', 'u16FltPerepheryCntBatch'],
				['u16FaultAirPressCnt', 'u16FaultAirPressCntBatch'],
				['u16SafetyCircuitCnt', 'u16SafetyCircuitCntBatch']
			],
			timeParams: [
				['u16StopFilterTime', 'u16StopFilterTimeBatch'],
				['u16StopProdFeedTime', 'u16StopProdFeedTimeBatch'],
				['u16FltFillingTime', 'u16FltFillingTimeBatch'],
				['u16FltWeigherTime', 'u16FltWeigherTimeBatch'],
				['u16FltPerepheryTime', 'u16FltPerepheryTimeBatch'],
				['u16FaultAirPressTime', 'u16FaultAirPressTimeBatch'],
				['u16SafetyCircuitTime', 'u16SafetyCircuitTimeBatch']
			],
			titleParams: [
				'A029FilterStop',
				'A031ProductFeedStop',
				'A221FaultBagFilling',
				'A224FaultWeigher',
				'A225FaultPeriphery',
				'A034CompAirFault',
				'A295SafetyCircuit'
			]
		};
	},

	get neutraleGoodProductionCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			['u8ProdGoodPercent', 'u8ProdGoodPercentBatch'],
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get neutraleBagCounterCard(): Array<Array<string>> {
		return [
			['u8SpoutProdPercent1', 'u8SpoutProdPercentBatch1'],
			['u8SpoutProdPercent2', 'u8SpoutProdPercentBatch2'],
			['u8SpoutProdPercent3', 'u8SpoutProdPercentBatch3'],
			['u8SpoutProdPercent4', 'u8SpoutProdPercentBatch4'],
			['u8SpoutProdPercent5', 'u8SpoutProdPercentBatch5'],
			['u8SpoutProdPercent6', 'u8SpoutProdPercentBatch6'],
			['u8SpoutProdPercent7', 'u8SpoutProdPercentBatch7'],
			['u8SpoutProdPercent8', 'u8SpoutProdPercentBatch8'],
			['u8SpoutProdPercent9', 'u8SpoutProdPercentBatch9'],
			['u8SpoutProdPercent10', 'u8SpoutProdPercentBatch10']
		];
	},

	get rvt8InterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16StopProdFeedCnt', 'u16StopProdFeedCntBatch'],
				['u16StopRadimatCnt', 'u16StopRadimatCntBatch'],
				['u16StopRotMotCnt', 'u16StopRotMotCntBatch'],
				['u16StopPalletizerCnt', 'u16StopPalletizerCntBatch'],
				['u16FaultAirPressCnt', 'u16FaultAirPressCntBatch'],
				['u16StopFilterCnt', 'u16StopFilterCntBatch']
				// ['u16SortChgCnt', 'u16SortChgCntBatch']
			],
			timeParams: [
				['u16StopProdFeedTime', 'u16StopProdFeedTimeBatch'],
				['u16StopRadimatTime', 'u16StopRadimatTimeBatch'],
				['u16StopRotMotTime', 'u16StopRotMotTimeBatch'],
				['u16StopPalletizerTime', 'u16StopPalletizerTimeBatch'],
				['u16FaultAirPressTime', 'u16FaultAirPressTimeBatch'],
				['u16StopFilterTime', 'u16StopFilterTimeBatch']
				// ['u16SortChgTime', 'u16SortChgTimeBatch']
			],
			titleParams: [
				'A031ProductFeedStop',
				'A032RadimatStop',
				'A030RotaryDriveStop',
				'A033PalletizerStop',
				'A034CompAirFault',
				'A029FilterStop'
				// 'A035TypeChange'
			]
		};
	},

	get rvt8RotoPckerSpeedRelatedProductionCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16PerfByRotationCnt_R_Y'),
			Util.getDuplicateArray('u16PerfByRotationCnt_Y_G'),
			Util.getDuplicateArray('u16PerfByRotationCntCurr'),
			Util.getDuplicateArray('u16PerfByRotationCnt')
		];
	},

	get rvt8RotoPackerGoodProductionCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			['u8ProdGoodPercent', 'u8ProdGoodPercentBatch'],
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get rvt8RotoPackerBagCounterCard(): Array<Array<string>> {
		return [
			['u8SpoutProdPercent1', 'u8SpoutProdPercentBatch1'],
			['u8SpoutProdPercent2', 'u8SpoutProdPercentBatch2'],
			['u8SpoutProdPercent3', 'u8SpoutProdPercentBatch3'],
			['u8SpoutProdPercent4', 'u8SpoutProdPercentBatch4'],
			['u8SpoutProdPercent5', 'u8SpoutProdPercentBatch5'],
			['u8SpoutProdPercent6', 'u8SpoutProdPercentBatch6'],
			['u8SpoutProdPercent7', 'u8SpoutProdPercentBatch7'],
			['u8SpoutProdPercent8', 'u8SpoutProdPercentBatch8'],
			['u8SpoutProdPercent9', 'u8SpoutProdPercentBatch9'],
			['u8SpoutProdPercent10', 'u8SpoutProdPercentBatch10'],
			['u8SpoutProdPercent11', 'u8SpoutProdPercentBatch11'],
			['u8SpoutProdPercent12', 'u8SpoutProdPercentBatch12'],
			['u8SpoutProdPercent13', 'u8SpoutProdPercentBatch13'],
			['u8SpoutProdPercent14', 'u8SpoutProdPercentBatch14'],
			['u8SpoutProdPercent15', 'u8SpoutProdPercentBatch15'],
			['u8SpoutProdPercent16', 'u8SpoutProdPercentBatch16']
		];
	},

	get rvt8RotoPackerNotplacedBagsCard(): Array<Array<string>> {
		return [
			['u32SpoutPlacedCnt1', 'u32SpoutPlacedCntBatch1'],
			['u32SpoutPlacedCnt2', 'u32SpoutPlacedCntBatch2'],
			['u32SpoutPlacedCnt3', 'u32SpoutPlacedCntBatch3'],
			['u32SpoutPlacedCnt4', 'u32SpoutPlacedCntBatch4'],
			['u32SpoutPlacedCnt5', 'u32SpoutPlacedCntBatch5'],
			['u32SpoutPlacedCnt6', 'u32SpoutPlacedCntBatch6'],
			['u32SpoutPlacedCnt7', 'u32SpoutPlacedCntBatch7'],
			['u32SpoutPlacedCnt8', 'u32SpoutPlacedCntBatch8'],
			['u32SpoutPlacedCnt9', 'u32SpoutPlacedCntBatch9'],
			['u32SpoutPlacedCnt10', 'u32SpoutPlacedCntBatch10'],
			['u32SpoutPlacedCnt11', 'u32SpoutPlacedCntBatch11'],
			['u32SpoutPlacedCnt12', 'u32SpoutPlacedCntBatch12'],
			['u32SpoutPlacedCnt13', 'u32SpoutPlacedCntBatch13'],
			['u32SpoutPlacedCnt14', 'u32SpoutPlacedCntBatch14'],
			['u32SpoutPlacedCnt15', 'u32SpoutPlacedCntBatch15'],
			['u32SpoutPlacedCnt16', 'u32SpoutPlacedCntBatch16']
		];
	},

	get terramDisruptionsCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16EmergencyStopCnt', 'u16EmergencyStopCntBatch'],
				['u16AccessDoorCnt', 'u16AccessDoorCntBatch'],
				['u16SafetyLightBarrierCnt', 'u16SafetyLightBarrierCntBatch'],
				['u16FaultUpstreamCnt', 'u16FaultUpstreamCntBatch'],
				['u16FaultEmptyPalCnt', 'u16FaultEmptyPalCntBatch'],
				['u16FaultPalletizerCnt', 'u16FaultPalletizerCntBatch'],
				['u16FaultFullPalCnt', 'u16FaultFullPalCntBatch']
			],
			timeParams: [
				['u16EmergencyStopTime', 'u16EmergencyStopTimeBatch'],
				['u16AccessDoorTime', 'u16AccessDoorTimeBatch'],
				['u16SafetyLightBarrierTime', 'u16SafetyLightBarrierTimeBatch'],
				['u16FaultUpstreamTime', 'u16FaultUpstreamTimeBatch'],
				['u16FaultEmptyPalTime', 'u16FaultEmptyPalTimeBatch'],
				['u16FaultPalletizerTime', 'u16FaultPalletizerTimeBatch'],
				['u16FaultFullPalTime', 'u16FaultFullPalTimeBatch']
			],
			titleParams: [
				'A145EmergencyStop',
				'A145AccessDoor',
				'A145DSafetyLightBarrier',
				'A145UpstreamSupply',
				'A207EmptyPalletTrans',
				'A208Palletizer',
				'A209FullPalletTrans'
			]
		};
	},

	get terramProductionCard(): Array<Array<string>> {
		return [
			['u8ShowUpPrograms', 'u8ShowUpProgramsBatch'],
			['u32ProductNumber1', 'u32ProductNumberBatch1'],
			['u32ProductNumber2', 'u32ProductNumberBatch2'],
			['u32ProductNumber3', 'u32ProductNumberBatch3'],
			['u32ProductNumber4', 'u32ProductNumberBatch4'],
			['u32ProductNumber5', 'u32ProductNumberBatch5'],
			['u32ProductNumber6', 'u32ProductNumberBatch6'],
			['u32ProductNumber7', 'u32ProductNumberBatch7'],
			['u32ProductNumber8', 'u32ProductNumberBatch8'],
			['u32ProductNumber9', 'u32ProductNumberBatch9'],
			['u32ProductNumber10', 'u32ProductNumberBatch10'],

			['u32FullPalletCnt1', 'u32FullPalletCntBatch1'],
			['u32FullPalletCnt2', 'u32FullPalletCntBatch2'],
			['u32FullPalletCnt3', 'u32FullPalletCntBatch3'],
			['u32FullPalletCnt4', 'u32FullPalletCntBatch4'],
			['u32FullPalletCnt5', 'u32FullPalletCntBatch5'],
			['u32FullPalletCnt6', 'u32FullPalletCntBatch6'],
			['u32FullPalletCnt7', 'u32FullPalletCntBatch7'],
			['u32FullPalletCnt8', 'u32FullPalletCntBatch8'],
			['u32FullPalletCnt9', 'u32FullPalletCntBatch9'],
			['u32FullPalletCnt10', 'u32FullPalletCntBatch10'],

			['u8PalletProdPercent1', 'u8PalletProdPercentBatch1'],
			['u8PalletProdPercent2', 'u8PalletProdPercentBatch2'],
			['u8PalletProdPercent3', 'u8PalletProdPercentBatch3'],
			['u8PalletProdPercent4', 'u8PalletProdPercentBatch4'],
			['u8PalletProdPercent5', 'u8PalletProdPercentBatch5'],
			['u8PalletProdPercent6', 'u8PalletProdPercentBatch6'],
			['u8PalletProdPercent7', 'u8PalletProdPercentBatch7'],
			['u8PalletProdPercent8', 'u8PalletProdPercentBatch8'],
			['u8PalletProdPercent9', 'u8PalletProdPercentBatch9'],
			['u8PalletProdPercent10', 'u8PalletProdPercentBatch10']
		];
	},

	get terramQuantityFullPalletCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('ryNotAvailable'),
			Util.getDuplicateArray('yGNotAvaialbe'),
			['u8GoodFullPalletPercent', 'u8GoodFullPalletPercentBatch'],
			Util.getDuplicateArray('maxNotAvailable')
		];
	},

	get powderpumpStrokeCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u16StrokePump1Cnt', 'u16StrokePump1CntBatch'],
			['u16StrokePump2Cnt', 'u16StrokePump2CntBatch'],
			['u16StrokePump3Cnt', 'u16StrokePump3CntBatch'],
			['u16StrokePump4Cnt', 'u16StrokePump4CntBatch']
		];
	},

	get maximumStrokeDurationCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u16CyclePump1Cnt', 'u16CyclePump1CntBatch'],
			['u16CyclePump2Cnt', 'u16CyclePump2CntBatch'],
			['u16CyclePump3Cnt', 'u16CyclePump3CntBatch'],
			['u16CyclePump4Cnt', 'u16CyclePump4CntBatch']
		];
	},

	get averageNumberOfStrokeCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8SpoutCnt'),
			['u16CycleBag1Cnt', 'u16CycleBag1CntBatch'],
			['u16CycleBag2Cnt', 'u16CycleBag2CntBatch'],
			['u16CycleBag3Cnt', 'u16CycleBag3CntBatch'],
			['u16CycleBag4Cnt', 'u16CycleBag4CntBatch']
		];
	},

	get bagShiftCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8ShiftCnt'),
			Util.getDuplicateArray('u32BagsPerShiftCnt1'),
			Util.getDuplicateArray('u32BagsPerShiftCnt2'),
			Util.getDuplicateArray('u32BagsPerShiftCnt3'),
			Util.getDuplicateArray('u32BagsPerShiftCnt4'),
			Util.getDuplicateArray('u32BagsPerShiftCnt5'),
			Util.getDuplicateArray('u32BagsPerShiftPercent1'),
			Util.getDuplicateArray('u32BagsPerShiftPercent2'),
			Util.getDuplicateArray('u32BagsPerShiftPercent3'),
			Util.getDuplicateArray('u32BagsPerShiftPercent4'),
			Util.getDuplicateArray('u32BagsPerShiftPercent5')
		];
	},

	get feigeGeneralCurrentProductionCard(): {
		variables: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			variables: [
				Util.getDuplicateArray('strExecuteOrder'),
				Util.getDuplicateArray('strProductID'),
				Util.getDuplicateArray('strBatchStart'),
				Util.getDuplicateArray('strTargetWeight'),
				Util.getDuplicateArray('strRemainingFill'),
				Util.getDuplicateArray('siNumberDec'),
				Util.getDuplicateArray('siNumberUnit'),
				Util.getDuplicateArray('u32BatchStart')
			],
			titleParams: [
				'F001_CurrentOrder',
				'F002_ProductID',
				'F003_BatchStart',
				'F004_Targetweight',
				'F005_RemainingFill'
			]
		};
	},

	get feigeGeneralCycleTimeMachineCard(): {
		variables: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			variables: [
				['uiCycleSum_day', 'uiCycleSum_batch'],
				['uiCycleCent_day', 'uiCycleCent_batch'],
				['uiCycleDebung_day', 'uiCycleDebung_batch'],
				['uiCycleInert_day', 'uiCycleInert_batch'],
				['uiCycleFill1_day', 'uiCycleFill1_batch'],
				['uiCycleFill2_day', 'uiCycleFill2_batch'],
				['uiCycleFill3_day', 'uiCycleFill3_batch'],
				['uiCycleFill4_day', 'uiCycleFill4_batch'],
				['uiCycleBunging_day', 'uiCycleBunging_batch'],
				['uiCycleSealing_day', 'uiCycleSealing_batch'],
				['uiCyclePos_day', 'uiCyclePos_batch'],
				['uiCycleTakt_day', 'uiCycleTakt_batch'],
				['uiCycleCW_day', 'uiCycleCW_batch'],
				['uiCycleClosing_day', 'uiCycleClosing_batch'],
				['uiCyclePalletizing_day', 'uiCyclePalletizing_batch'],
				['uiCycleEmptyPal_day', 'uiCycleEmptyPal_batch'],
				['uiCylceDePal_day', 'uiCylceDePal_batch'],
				['uiCycleTransport_day', 'uiCycleTransport_batch'],
				['uiCyclePailSep_day', 'uiCyclePailSep_batch'],
				['uiCycleHangUpCap_day', 'uiCycleHangUpCap_batch'],
				['uiCyclePuschInCap_day', 'uiCyclePuschInCap_batch'],
				['uiCycleTestConsoles_day', 'uiCycleTestConsoles_batch']
			],

			titleParams: [
				'F010_Sum',
				'F011_Centering',
				'F012_Debunging',
				'F013_Inert',
				'F014_Filling_1',
				'F015_Filling_2',
				'F016_Filling_3',
				'F017_Filling_4',
				'F018_Bunging',
				'F019_Sealing',
				'F020_Position',
				'F021_TransportCycle',
				'F022_CheckWeigher',
				'F023_Closing',
				'F024_Palletizing',
				'F025_EmptyPaletMagazin',
				'F026_DePalletizing',
				'F027_Transport',
				'F028_PailSeperator',
				'F029_HangUpCap',
				'F030_PushInCap',
				'F031_TestConsoles'
			]
		};
	},

	get feigeGeneralFullStatisticsCard(): {
		variables: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			variables: [
				['diTU2_day', 'diTU2_batch'],
				['diTU1_day', 'diTU1_batch'],
				['diLowerTol_day', 'diLowerTol_batch'],
				['diInTol_day', 'diInTol_batch'],
				['diUpperTol_day', 'diUpperTol_batch'],
				['diTO1_day', 'diTO1_batch'],
				['diTO2_day', 'diTO2_batch']
			],

			titleParams: [
				'F050_TU2',
				'F051_TU1',
				'F052_LowerTol',
				'F053_InTol',
				'F054_UpperTol',
				'F055_TO1',
				'F056_TO2'
			]
		};
	},

	get feigeGeneralInterruptionsTodayCard(): {
		countVariables: Array<Array<string>>;
		timeVariables: Array<Array<string>>;
		titleVariables: Array<string>;
	} {
		return {
			countVariables: [
				['uiErrCountProdIn_day', 'uiErrCountProdIn_batch'],
				['uiErrCountEmptyTruss_day', 'uiErrCountEmptyTruss_batch'],
				['uiErrCountCentering_day', 'uiErrCountCentering_batch'],
				['uiErrCountDebunging_day', 'uiErrCountDebunging_batch'],
				['uiErrCountInert_day', 'uiErrCountInert_batch'],

				['uiErrCountFill1_day', 'uiErrCountFill1_batch'],
				['uiErrCountFill2_day', 'uiErrCountFill2_batch'],
				['uiErrCountFill3_day', 'uiErrCountFill3_batch'],
				['uiErrCountFill4_day', 'uiErrCountFill4_batch'],
				['uiErrCountBunging_day', 'uiErrCountBunging_batch'],

				['uiErrCountSealing_day', 'uiErrCountSealing_batch'],
				['uiErrCountPosition_day', 'uiErrCountPosition_batch'],
				['uiErrCountTransportCycle_day', 'uiErrCountTransportCycle_batch'],
				['uiErrCountCheckWeigher_day', 'uiErrCountCheckWeigher_batch'],
				['uiErrCountClosing_day', 'uiErrCountClosing_batch'],

				['uiErrCountPalletizing_day', 'uiErrCountPalletizing_batch'],
				[
					'uiErrCountEmptyPaletMagazin_day',
					'uiErrCountEmptyPaletMagazin_batch'
				],
				['uiErrCountDePalletizing_day', 'uiErrCountDePalletizing_batch'],
				['uiErrCountTransport_day', 'uiErrCountTransport_batch'],
				['uiErrCountPailSeperator_day', 'uiErrCountPailSeperator_batch'],

				['uiErrCountHangUpCap_day', 'uiErrCountHangUpCap_batch'],
				['uiErrCountPushInCap_day', 'uiErrCountPushInCap_batch'],
				['uiErrCountTestConsoles_day', 'uiErrCountTestConsoles_batch']
			],

			timeVariables: [
				['uiErrTimeProdIn_day', 'uiErrTimeProdIn_batch'],
				['uiErrTimeEmptyTruss_day', 'uiErrTimeEmptyTruss_batch'],
				['uiErrTimeCentering_day', 'uiErrTimeCentering_batch'],
				['uiErrTimeDebunging_day', 'uiErrTimeDebunging_batch'],
				['uiErrTimeInert_day', 'uiErrTimeInert_batch'],

				['uiErrTimeFill1_day', 'uiErrTimeFill1_batch'],
				['uiErrTimeFill2_day', 'uiErrTimeFill2_batch'],
				['uiErrTimeFill3_day', 'uiErrTimeFill3_batch'],
				['uiErrTimeFill4_day', 'uiErrTimeFill4_batch'],
				['uiErrTimeBunging_day', 'uiErrTimeBunging_batch'],

				['uiErrTimeSealing_day', 'uiErrTimeSealing_batch'],
				['uiErrTimePosition_day', 'uiErrTimePosition_batch'],
				['uiErrTimeTransportCycle_day', 'uiErrTimeTransportCycle_batch'],
				['uiErrTimeCheckWeigher_day', 'uiErrTimeCheckWeigher_batch'],
				['uiErrTimeClosing_day', 'uiErrTimeClosing_batch'],

				['uiErrTimePalletizing_day', 'uiErrTimePalletizing_batch'],
				['uiErrTimeEmptyPaletMagazin_day', 'uiErrTimeEmptyPaletMagazin_batch'],
				['uiErrTimeDePalletizing_day', 'uiErrTimeDePalletizing_batch'],
				['uiErrTimeTransport_day', 'uiErrTimeTransport_batch'],
				['uiErrTimePailSeperator_day', 'uiErrTimePailSeperator_batch'],

				['uiErrTimeHangUpCap_day', 'uiErrTimeHangUpCap_batch'],
				['uiErrTimePushInCap_day', 'uiErrTimePushInCap_batch'],
				['uiErrTimeTestConsoles_day', 'uiErrTimeTestConsoles_batch']
			],

			titleVariables: [
				'F060_ProductInfeed',
				'F061_EmptyTruss',
				'F011_Centering',
				'F012_Debunging',
				'F013_Inert',

				'F014_Filling_1',
				'F015_Filling_2',
				'F016_Filling_3',
				'F017_Filling_4',
				'F018_Bunging',

				'F019_Sealing',
				'F020_Position',
				'F021_TransportCycle',
				'F022_CheckWeigher',
				'F023_Closing',

				'F024_Palletizing',
				'F025_EmptyPaletMagazin',
				'F026_DePalletizing',
				'F027_Transport',
				'F028_PailSeperator',

				'F029_HangUpCap',
				'F030_PushInCap',
				'F031_TestConsoles'
			]
		};
	},

	get overViewPageGaugeCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16MachPerf_R_Y'),
			Util.getDuplicateArray('u16MachPerf_Y_G'),
			Util.getDuplicateArray('u16MachPerf'),
			Util.getDuplicateArray('u16MachPerf_Max')
		];
	},

	get oeeTabOeeValueCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('s16OeeVal_RY'),
			Util.getDuplicateArray('s16OeeVal_YG'),
			Util.getDuplicateArray('s16OeeValCur'),
			Util.getDuplicateArray('s16OeeValmaxNotAvailable')
		];
	},

	get oeeTabAvailabilityCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('s16OeeAvail_RY'),
			Util.getDuplicateArray('s16OeeAvail_YG'),
			Util.getDuplicateArray('s16OeeAvailCur'),
			Util.getDuplicateArray('s16OeeAvailmaxNotAvailable')
		];
	},

	get oeeTabPerformanceCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('s16OeePerf_RY'),
			Util.getDuplicateArray('s16OeePerf_YG'),
			Util.getDuplicateArray('s16OeePerfCur'),
			Util.getDuplicateArray('s16OeePerfMaxNotAvailable')
		];
	},

	get oeeTabQualityCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('s16OeeQual_RY'),
			Util.getDuplicateArray('s16OeeQual_YG'),
			Util.getDuplicateArray('s16OeeQualCur'),
			Util.getDuplicateArray('s16OeeQualCurMaxNotAvailable')
		];
	},

	get oeeTabRejectsCard(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('u32RejectCnt'),
				Util.getDuplicateArray('u16RejectsWrongWeight'),
				Util.getDuplicateArray('u16RejectsOpenBag'),
				Util.getDuplicateArray('u16RejectsSampleBag'),
				Util.getDuplicateArray('u16MetalDetectorCnt')
			],
			titleParams: [
				'A471RejectsTotal',
				'A472RejectsIncorrect',
				'A473RejectsOpen',
				'A474RejectsSample',
				'A475RejectsMetal'
			]
		};
	},

	get generalPageProductionCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16MachPerf_R_Y'),
			Util.getDuplicateArray('u16MachPerf_Y_G'),
			Util.getDuplicateArray('u16MachPerf'),
			Util.getDuplicateArray('u16MachPerf_Max')
		];
	},

	get generalPageAvailabilityCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u8MachAvail_R_Y'),
			Util.getDuplicateArray('u8MachAvail_Y_G'),
			['u8MachAvail', 'u8MachAvailBatch'],
			Util.getDuplicateArray('MaxNotAvailable')
		];
	},

	get generalTabMaintenanceCard(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u32MaintGenCurr'),
			Util.getDuplicateArray('u32MaintGen'),
			Util.getDuplicateArray('minNotAvailable')
		];
	},

	get sensorL1localAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL1MainAcc_R_Y'),
			Util.getDuplicateArray('f16SensorL1MainAcc_Y_G'),
			Util.getDuplicateArray('sensor_L1_main_acc'),
			Util.getDuplicateArray('f16SensorL1MainAcc_Max'),
			Util.getDuplicateArray('f16SensorL1MainAcc_Min'),
			Util.getDuplicateArray('sensor_L1_main_acc'),
			Util.getDuplicateArray('f16SensorL1MainAcc_G_Y'),
			Util.getDuplicateArray('f16SensorL1MainAcc_Y_R')
		];
	},

	get sensorL1localRotationFrequencyCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16SensorL1Freq_R_Y'),
			Util.getDuplicateArray('u16SensorL1Freq_Y_G'),
			Util.getDuplicateArray('sensor_L1_freq'),
			Util.getDuplicateArray('u16SensorL1Freq_Max'),
			Util.getDuplicateArray('u16SensorL1Freq_Min'),
			Util.getDuplicateArray('sensor_L1_freq'),
			Util.getDuplicateArray('u16SensorL1Freq_G_Y'),
			Util.getDuplicateArray('u16SensorL1Freq_Y_R')
		];
	},

	get sensorL1localAmplitudeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL1Ampl_R_Y'),
			Util.getDuplicateArray('f16SensorL1Ampl_Y_G'),
			Util.getDuplicateArray('sensor_L1_ampl'),
			Util.getDuplicateArray('f16SensorL1Ampl_Max'),
			Util.getDuplicateArray('f16SensorL1Ampl_Min'),
			Util.getDuplicateArray('sensor_L1_ampl'),
			Util.getDuplicateArray('f16SensorL1Ampl_G_Y'),
			Util.getDuplicateArray('f16SensorL1Ampl_Y_R')
		];
	},

	get sensorL1Acceleration(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_L1_x_acc'),
				Util.getDuplicateArray('sensor_L1_y_acc'),
				Util.getDuplicateArray('sensor_L1_z_acc')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorL1ZAxisAcceleration(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL1ZAcc_G_Y'),
			Util.getDuplicateArray('f16SensorL1ZAcc_Y_R'),
			Util.getDuplicateArray('sensor_L1_z_acc'),
			Util.getDuplicateArray('f16SensorL1ZAcc_Max')
		];
	},

	get sensorL1Displacement(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_L1_x_disp'),
				Util.getDuplicateArray('sensor_L1_y_disp'),
				Util.getDuplicateArray('sensor_L1_z_disp')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},


	get sensorL1PhasedAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL1PhasedAngle_R_Y'),
			Util.getDuplicateArray('f16SensorL1PhasedAngle_Y_G'),
			Util.getDuplicateArray('sensor_L1_phase_angle'),
			Util.getDuplicateArray('f16SensorL1PhasedAngle_Max'),
			Util.getDuplicateArray('f16SensorL1PhasedAngle_Min'),
			Util.getDuplicateArray('sensor_L1_phase_angle'),
			Util.getDuplicateArray('f16SensorL1PhasedAngle_G_Y'),
			Util.getDuplicateArray('f16SensorL1PhasedAngle_Y_R')
		];
	},

	get sensorL1MotionAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL1MotionAngle_R_Y'),
			Util.getDuplicateArray('f16SensorL1MotionAngle_Y_G'),
			Util.getDuplicateArray('sensor_L1_motion_angle'),
			Util.getDuplicateArray('f16SensorL1MotionAngle_Max'),
			Util.getDuplicateArray('f16SensorL1MotionAngle_Min'),
			Util.getDuplicateArray('sensor_L1_motion_angle'),
			Util.getDuplicateArray('f16SensorL1MotionAngle_G_Y'),
			Util.getDuplicateArray('f16SensorL1MotionAngle_Y_R')
		];
	},


	get sensorL1maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL1Temp_Min'),
			Util.getDuplicateArray('sensor_L1_temp'),
			Util.getDuplicateArray('f16SensorL1Temp_Max'),
			Util.getDuplicateArray('f16SensorL1Temp_G_Y'),
			Util.getDuplicateArray('f16SensorL1Temp_Y_R')
		];
	},

	get sensorL2localAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL2MainAcc_R_Y'),
			Util.getDuplicateArray('f16SensorL2MainAcc_Y_G'),
			Util.getDuplicateArray('sensor_L2_main_acc'),
			Util.getDuplicateArray('f16SensorL2MainAcc_Max'),
			Util.getDuplicateArray('f16SensorL2MainAcc_Min'),
			Util.getDuplicateArray('sensor_L2_main_acc'),
			Util.getDuplicateArray('f16SensorL2MainAcc_G_Y'),
			Util.getDuplicateArray('f16SensorL2MainAcc_Y_R')
		];
	},

	get sensorL2localRotationFrequencyCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16SensorL2Freq_R_Y'),
			Util.getDuplicateArray('u16SensorL2Freq_Y_G'),
			Util.getDuplicateArray('sensor_L2_freq'),
			Util.getDuplicateArray('u16SensorL2Freq_Max'),
			Util.getDuplicateArray('u16SensorL2Freq_Min'),
			Util.getDuplicateArray('sensor_L2_freq'),
			Util.getDuplicateArray('u16SensorL2Freq_G_Y'),
			Util.getDuplicateArray('u16SensorL2Freq_Y_R')
		];
	},

	get sensorL2localAmplitudeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL2Ampl_R_Y'),
			Util.getDuplicateArray('f16SensorL2Ampl_Y_G'),
			Util.getDuplicateArray('sensor_L2_ampl'),
			Util.getDuplicateArray('f16SensorL2Ampl_Max'),
			Util.getDuplicateArray('f16SensorL2Ampl_Min'),
			Util.getDuplicateArray('sensor_L2_ampl'),
			Util.getDuplicateArray('f16SensorL2Ampl_G_Y'),
			Util.getDuplicateArray('f16SensorL2Ampl_Y_R')
		];
	},

	get sensorL2PhasedAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL2PhasedAngle_R_Y'),
			Util.getDuplicateArray('f16SensorL2PhasedAngle_Y_G'),
			Util.getDuplicateArray('sensor_L2_phase_angle'),
			Util.getDuplicateArray('f16SensorL2PhasedAngle_Max'),
			Util.getDuplicateArray('f16SensorL2PhasedAngle_Min'),
			Util.getDuplicateArray('sensor_L2_phase_angle'),
			Util.getDuplicateArray('f16SensorL2PhasedAngle_G_Y'),
			Util.getDuplicateArray('f16SensorL2PhasedAngle_Y_R')
		];
	},

	get sensorL2MotionAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL2MotionAngle_R_Y'),
			Util.getDuplicateArray('f16SensorL2MotionAngle_Y_G'),
			Util.getDuplicateArray('sensor_L2_motion_angle'),
			Util.getDuplicateArray('f16SensorL2MotionAngle_Max'),
			Util.getDuplicateArray('f16SensorL2MotionAngle_Min'),
			Util.getDuplicateArray('sensor_L2_motion_angle'),
			Util.getDuplicateArray('f16SensorL2MotionAngle_G_Y'),
			Util.getDuplicateArray('f16SensorL2MotionAngle_Y_R')
		];
	},

	get sensorL2Acceleration(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_L2_x_acc'),
				Util.getDuplicateArray('sensor_L2_y_acc'),
				Util.getDuplicateArray('sensor_L2_z_acc')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorL2ZAxisAcceleration(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL2ZAcc_G_Y'),
			Util.getDuplicateArray('f16SensorL2ZAcc_Y_R'),
			Util.getDuplicateArray('sensor_L2_z_acc'),
			Util.getDuplicateArray('f16SensorL2ZAcc_Max')
		];
	},

	get sensorL2Displacement(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_L2_x_disp'),
				Util.getDuplicateArray('sensor_L2_y_disp'),
				Util.getDuplicateArray('sensor_L2_z_disp')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorL2maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL2Temp_Min'),
			Util.getDuplicateArray('sensor_L2_temp'),
			Util.getDuplicateArray('f16SensorL2Temp_Max'),
			Util.getDuplicateArray('f16SensorL2Temp_G_Y'),
			Util.getDuplicateArray('f16SensorL2Temp_Y_R')
		];
	},

	get sensorL3localAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL3MainAcc_R_Y'),
			Util.getDuplicateArray('f16SensorL3MainAcc_Y_G'),
			Util.getDuplicateArray('sensor_L3_main_acc'),
			Util.getDuplicateArray('f16SensorL3MainAcc_Max'),
			Util.getDuplicateArray('f16SensorL3MainAcc_Min'),
			Util.getDuplicateArray('sensor_L3_main_acc'),
			Util.getDuplicateArray('f16SensorL3MainAcc_G_Y'),
			Util.getDuplicateArray('f16SensorL3MainAcc_Y_R')
		];
	},

	get sensorL3localRotationFrequencyCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16SensorL3Freq_R_Y'),
			Util.getDuplicateArray('u16SensorL3Freq_Y_G'),
			Util.getDuplicateArray('sensor_L3_freq'),
			Util.getDuplicateArray('u16SensorL3Freq_Max'),
			Util.getDuplicateArray('u16SensorL3Freq_Min'),
			Util.getDuplicateArray('sensor_L3_freq'),
			Util.getDuplicateArray('u16SensorL3Freq_G_Y'),
			Util.getDuplicateArray('u16SensorL3Freq_Y_R')
		];
	},

	get sensorL3localAmplitudeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL3Ampl_R_Y'),
			Util.getDuplicateArray('f16SensorL3Ampl_Y_G'),
			Util.getDuplicateArray('sensor_L3_ampl'),
			Util.getDuplicateArray('f16SensorL3Ampl_Max'),
			Util.getDuplicateArray('f16SensorL3Ampl_Min'),
			Util.getDuplicateArray('sensor_L3_ampl'),
			Util.getDuplicateArray('f16SensorL3Ampl_G_Y'),
			Util.getDuplicateArray('f16SensorL3Ampl_Y_R')
		];
	},

	get sensorL3PhasedAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL3PhasedAngle_R_Y'),
			Util.getDuplicateArray('f16SensorL3PhasedAngle_Y_G'),
			Util.getDuplicateArray('sensor_L3_phase_angle'),
			Util.getDuplicateArray('f16SensorL3PhasedAngle_Max'),
			Util.getDuplicateArray('f16SensorL3PhasedAngle_Min'),
			Util.getDuplicateArray('sensor_L3_phase_angle'),
			Util.getDuplicateArray('f16SensorL3PhasedAngle_G_Y'),
			Util.getDuplicateArray('f16SensorL3PhasedAngle_Y_R')
		];
	},

	get sensorL3MotionAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL3MotionAngle_R_Y'),
			Util.getDuplicateArray('f16SensorL3MotionAngle_Y_G'),
			Util.getDuplicateArray('sensor_L3_motion_angle'),
			Util.getDuplicateArray('f16SensorL3MotionAngle_Max'),
			Util.getDuplicateArray('f16SensorL3MotionAngle_Min'),
			Util.getDuplicateArray('sensor_L3_motion_angle'),
			Util.getDuplicateArray('f16SensorL3MotionAngle_G_Y'),
			Util.getDuplicateArray('f16SensorL3MotionAngle_Y_R')
		];
	},

	get sensorL3Acceleration(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_L3_x_acc'),
				Util.getDuplicateArray('sensor_L3_y_acc'),
				Util.getDuplicateArray('sensor_L3_z_acc')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorL3ZAxisAcceleration(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL3ZAcc_G_Y'),
			Util.getDuplicateArray('f16SensorL3ZAcc_Y_R'),
			Util.getDuplicateArray('sensor_L3_z_acc'),
			Util.getDuplicateArray('f16SensorL3ZAcc_Max')
		];
	},

	get sensorL3Displacement(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_L3_x_disp'),
				Util.getDuplicateArray('sensor_L3_y_disp'),
				Util.getDuplicateArray('sensor_L3_z_disp')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorL3maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL3Temp_Min'),
			Util.getDuplicateArray('sensor_L3_temp'),
			Util.getDuplicateArray('f16SensorL3Temp_Max'),
			Util.getDuplicateArray('f16SensorL3Temp_G_Y'),
			Util.getDuplicateArray('f16SensorL3Temp_Y_R')
		];
	},

	get sensorL4localAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL4MainAcc_R_Y'),
			Util.getDuplicateArray('f16SensorL4MainAcc_Y_G'),
			Util.getDuplicateArray('sensor_L4_main_acc'),
			Util.getDuplicateArray('f16SensorL4MainAcc_Max'),
			Util.getDuplicateArray('f16SensorL4MainAcc_Min'),
			Util.getDuplicateArray('sensor_L4_main_acc'),
			Util.getDuplicateArray('f16SensorL4MainAcc_G_Y'),
			Util.getDuplicateArray('f16SensorL4MainAcc_Y_R')
		];
	},

	get sensorL4localRotationFrequencyCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16SensorL4Freq_R_Y'),
			Util.getDuplicateArray('u16SensorL4Freq_Y_G'),
			Util.getDuplicateArray('sensor_L4_freq'),
			Util.getDuplicateArray('u16SensorL4Freq_Max'),
			Util.getDuplicateArray('u16SensorL4Freq_Min'),
			Util.getDuplicateArray('sensor_L4_freq'),
			Util.getDuplicateArray('u16SensorL4Freq_G_Y'),
			Util.getDuplicateArray('u16SensorL4Freq_Y_R')
		];
	},

	get sensorL4localAmplitudeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL4Ampl_R_Y'),
			Util.getDuplicateArray('f16SensorL4Ampl_Y_G'),
			Util.getDuplicateArray('sensor_L4_ampl'),
			Util.getDuplicateArray('f16SensorL4Ampl_Max'),
			Util.getDuplicateArray('f16SensorL4Ampl_Min'),
			Util.getDuplicateArray('sensor_L4_ampl'),
			Util.getDuplicateArray('f16SensorL4Ampl_G_Y'),
			Util.getDuplicateArray('f16SensorL4Ampl_Y_R')
		];
	},

	get sensorL4PhasedAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL4PhasedAngle_R_Y'),
			Util.getDuplicateArray('f16SensorL4PhasedAngle_Y_G'),
			Util.getDuplicateArray('sensor_L4_phase_angle'),
			Util.getDuplicateArray('f16SensorL4PhasedAngle_Max'),
			Util.getDuplicateArray('f16SensorL4PhasedAngle_Min'),
			Util.getDuplicateArray('sensor_L4_phase_angle'),
			Util.getDuplicateArray('f16SensorL4PhasedAngle_G_Y'),
			Util.getDuplicateArray('f16SensorL4PhasedAngle_Y_R')
		];
	},

	get sensorL4MotionAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL4MotionAngle_R_Y'),
			Util.getDuplicateArray('f16SensorL4MotionAngle_Y_G'),
			Util.getDuplicateArray('sensor_L4_motion_angle'),
			Util.getDuplicateArray('f16SensorL4MotionAngle_Max'),
			Util.getDuplicateArray('f16SensorL4MotionAngle_Min'),
			Util.getDuplicateArray('sensor_L4_motion_angle'),
			Util.getDuplicateArray('f16SensorL4MotionAngle_G_Y'),
			Util.getDuplicateArray('f16SensorL4MotionAngle_Y_R')
		];
	},

	get sensorL4Acceleration(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_L4_x_acc'),
				Util.getDuplicateArray('sensor_L4_y_acc'),
				Util.getDuplicateArray('sensor_L4_z_acc')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorL4ZAxisAcceleration(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL4ZAcc_G_Y'),
			Util.getDuplicateArray('f16SensorL4ZAcc_Y_R'),
			Util.getDuplicateArray('sensor_L4_z_acc'),
			Util.getDuplicateArray('f16SensorL4ZAcc_Max')
		];
	},

	get sensorL4Displacement(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_L4_x_disp'),
				Util.getDuplicateArray('sensor_L4_y_disp'),
				Util.getDuplicateArray('sensor_L4_z_disp')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorL4maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorL4Temp_Min'),
			Util.getDuplicateArray('sensor_L4_temp'),
			Util.getDuplicateArray('f16SensorL4Temp_Max'),
			Util.getDuplicateArray('f16SensorL4Temp_G_Y'),
			Util.getDuplicateArray('f16SensorL4Temp_Y_R')
		];
	},

	get sensorR1localAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR1MainAcc_R_Y'),
			Util.getDuplicateArray('f16SensorR1MainAcc_Y_G'),
			Util.getDuplicateArray('sensor_R1_main_acc'),
			Util.getDuplicateArray('f16SensorR1MainAcc_Max'),
			Util.getDuplicateArray('f16SensorR1MainAcc_Min'),
			Util.getDuplicateArray('sensor_R1_main_acc'),
			Util.getDuplicateArray('f16SensorR1MainAcc_G_Y'),
			Util.getDuplicateArray('f16SensorR1MainAcc_Y_R')
		];
	},

	get sensorR1localRotationFrequencyCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16SensorR1Freq_R_Y'),
			Util.getDuplicateArray('u16SensorR1Freq_Y_G'),
			Util.getDuplicateArray('sensor_R1_freq'),
			Util.getDuplicateArray('u16SensorR1Freq_Max'),
			Util.getDuplicateArray('u16SensorR1Freq_Min'),
			Util.getDuplicateArray('sensor_R1_freq'),
			Util.getDuplicateArray('u16SensorR1Freq_G_Y'),
			Util.getDuplicateArray('u16SensorR1Freq_Y_R')
		];
	},

	get sensorR1localAmplitudeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR1Ampl_R_Y'),
			Util.getDuplicateArray('f16SensorR1Ampl_Y_G'),
			Util.getDuplicateArray('sensor_R1_ampl'),
			Util.getDuplicateArray('f16SensorR1Ampl_Max'),
			Util.getDuplicateArray('f16SensorR1Ampl_Min'),
			Util.getDuplicateArray('sensor_R1_ampl'),
			Util.getDuplicateArray('f16SensorR1Ampl_G_Y'),
			Util.getDuplicateArray('f16SensorR1Ampl_Y_R')
		];
	},

	get sensorR1PhasedAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR1PhasedAngle_R_Y'),
			Util.getDuplicateArray('f16SensorR1PhasedAngle_Y_G'),
			Util.getDuplicateArray('sensor_R1_phase_angle'),
			Util.getDuplicateArray('f16SensorR1PhasedAngle_Max'),
			Util.getDuplicateArray('f16SensorR1PhasedAngle_Min'),
			Util.getDuplicateArray('sensor_R1_phase_angle'),
			Util.getDuplicateArray('f16SensorR1PhasedAngle_G_Y'),
			Util.getDuplicateArray('f16SensorR1PhasedAngle_Y_R')
		];
	},

	get sensorR1MotionAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR1MotionAngle_R_Y'),
			Util.getDuplicateArray('f16SensorR1MotionAngle_Y_G'),
			Util.getDuplicateArray('sensor_R1_motion_angle'),
			Util.getDuplicateArray('f16SensorR1MotionAngle_Max'),
			Util.getDuplicateArray('f16SensorR1MotionAngle_Min'),
			Util.getDuplicateArray('sensor_R1_motion_angle'),
			Util.getDuplicateArray('f16SensorR1MotionAngle_G_Y'),
			Util.getDuplicateArray('f16SensorR1MotionAngle_Y_R')
		];
	},

	get sensorR1Acceleration(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_R1_x_acc'),
				Util.getDuplicateArray('sensor_R1_y_acc'),
				Util.getDuplicateArray('sensor_R1_z_acc')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorR1ZAxisAcceleration(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR1ZAcc_G_Y'),
			Util.getDuplicateArray('f16SensorR1ZAcc_Y_R'),
			Util.getDuplicateArray('sensor_R1_z_acc'),
			Util.getDuplicateArray('f16SensorR1ZAcc_Max')
		];
	},

	get sensorR1Displacement(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_R1_x_disp'),
				Util.getDuplicateArray('sensor_R1_y_disp'),
				Util.getDuplicateArray('sensor_R1_z_disp')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorR1maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR1Temp_Min'),
			Util.getDuplicateArray('sensor_R1_temp'),
			Util.getDuplicateArray('f16SensorR1Temp_Max'),
			Util.getDuplicateArray('f16SensorR1Temp_G_Y'),
			Util.getDuplicateArray('f16SensorR1Temp_Y_R')
		];
	},

	get sensorR2localAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR2MainAcc_R_Y'),
			Util.getDuplicateArray('f16SensorR2MainAcc_Y_G'),
			Util.getDuplicateArray('sensor_R2_main_acc'),
			Util.getDuplicateArray('f16SensorR2MainAcc_Max'),
			Util.getDuplicateArray('f16SensorR2MainAcc_Min'),
			Util.getDuplicateArray('sensor_R2_main_acc'),
			Util.getDuplicateArray('f16SensorR2MainAcc_G_Y'),
			Util.getDuplicateArray('f16SensorR2MainAcc_Y_R')
		];
	},

	get sensorR2localRotationFrequencyCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16SensorR2Freq_R_Y'),
			Util.getDuplicateArray('u16SensorR2Freq_Y_G'),
			Util.getDuplicateArray('sensor_R2_freq'),
			Util.getDuplicateArray('u16SensorR2Freq_Max'),
			Util.getDuplicateArray('u16SensorR2Freq_Min'),
			Util.getDuplicateArray('sensor_R2_freq'),
			Util.getDuplicateArray('u16SensorR2Freq_G_Y'),
			Util.getDuplicateArray('u16SensorR2Freq_Y_R')
		];
	},

	get sensorR2localAmplitudeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR2Ampl_R_Y'),
			Util.getDuplicateArray('f16SensorR2Ampl_Y_G'),
			Util.getDuplicateArray('sensor_R2_ampl'),
			Util.getDuplicateArray('f16SensorR2Ampl_Max'),
			Util.getDuplicateArray('f16SensorR2Ampl_Min'),
			Util.getDuplicateArray('sensor_R2_ampl'),
			Util.getDuplicateArray('f16SensorR2Ampl_G_Y'),
			Util.getDuplicateArray('f16SensorR2Ampl_Y_R')
		];
	},

	get sensorR2PhasedAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR2PhasedAngle_R_Y'),
			Util.getDuplicateArray('f16SensorR2PhasedAngle_Y_G'),
			Util.getDuplicateArray('sensor_R2_phase_angle'),
			Util.getDuplicateArray('f16SensorR2PhasedAngle_Max'),
			Util.getDuplicateArray('f16SensorR2PhasedAngle_Min'),
			Util.getDuplicateArray('sensor_R2_phase_angle'),
			Util.getDuplicateArray('f16SensorR2PhasedAngle_G_Y'),
			Util.getDuplicateArray('f16SensorR2PhasedAngle_Y_R')
		];
	},

	get sensorR2MotionAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR2MotionAngle_R_Y'),
			Util.getDuplicateArray('f16SensorR2MotionAngle_Y_G'),
			Util.getDuplicateArray('sensor_R2_motion_angle'),
			Util.getDuplicateArray('f16SensorR2MotionAngle_Max'),
			Util.getDuplicateArray('f16SensorR2MotionAngle_Min'),
			Util.getDuplicateArray('sensor_R2_motion_angle'),
			Util.getDuplicateArray('f16SensorR2MotionAngle_G_Y'),
			Util.getDuplicateArray('f16SensorR2MotionAngle_Y_R')
		];
	},

	get sensorR2Acceleration(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_R2_x_acc'),
				Util.getDuplicateArray('sensor_R2_y_acc'),
				Util.getDuplicateArray('sensor_R2_z_acc')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorR2ZAxisAcceleration(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR2ZAcc_G_Y'),
			Util.getDuplicateArray('f16SensorR2ZAcc_Y_R'),
			Util.getDuplicateArray('sensor_R2_z_acc'),
			Util.getDuplicateArray('f16SensorR2ZAcc_Max')
		];
	},

	get sensorR2Displacement(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_R2_x_disp'),
				Util.getDuplicateArray('sensor_R2_y_disp'),
				Util.getDuplicateArray('sensor_R2_z_disp')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorR2maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR2Temp_Min'),
			Util.getDuplicateArray('sensor_R2_temp'),
			Util.getDuplicateArray('f16SensorR2Temp_Max'),
			Util.getDuplicateArray('f16SensorR2Temp_G_Y'),
			Util.getDuplicateArray('f16SensorR2Temp_Y_R')
		];
	},

	get sensorR3localAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR3MainAcc_R_Y'),
			Util.getDuplicateArray('f16SensorR3MainAcc_Y_G'),
			Util.getDuplicateArray('sensor_R3_main_acc'),
			Util.getDuplicateArray('f16SensorR3MainAcc_Max'),
			Util.getDuplicateArray('f16SensorR3MainAcc_Min'),
			Util.getDuplicateArray('sensor_R3_main_acc'),
			Util.getDuplicateArray('f16SensorR3MainAcc_G_Y'),
			Util.getDuplicateArray('f16SensorR3MainAcc_Y_R')
		];
	},

	get sensorR3localRotationFrequencyCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16SensorR3Freq_R_Y'),
			Util.getDuplicateArray('u16SensorR3Freq_Y_G'),
			Util.getDuplicateArray('sensor_R3_freq'),
			Util.getDuplicateArray('u16SensorR3Freq_Max'),
			Util.getDuplicateArray('u16SensorR3Freq_Min'),
			Util.getDuplicateArray('sensor_R3_freq'),
			Util.getDuplicateArray('u16SensorR3Freq_G_Y'),
			Util.getDuplicateArray('u16SensorR3Freq_Y_R')
		];
	},

	get sensorR3localAmplitudeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR3Ampl_R_Y'),
			Util.getDuplicateArray('f16SensorR3Ampl_Y_G'),
			Util.getDuplicateArray('sensor_R3_ampl'),
			Util.getDuplicateArray('f16SensorR3Ampl_Max'),
			Util.getDuplicateArray('f16SensorR3Ampl_Min'),
			Util.getDuplicateArray('sensor_R3_ampl'),
			Util.getDuplicateArray('f16SensorR3Ampl_G_Y'),
			Util.getDuplicateArray('f16SensorR3Ampl_Y_R')
		];
	},

	get sensorR3PhasedAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR3PhasedAngle_R_Y'),
			Util.getDuplicateArray('f16SensorR3PhasedAngle_Y_G'),
			Util.getDuplicateArray('sensor_R3_phase_angle'),
			Util.getDuplicateArray('f16SensorR3PhasedAngle_Max'),
			Util.getDuplicateArray('f16SensorR3PhasedAngle_Min'),
			Util.getDuplicateArray('sensor_R3_phase_angle'),
			Util.getDuplicateArray('f16SensorR3PhasedAngle_G_Y'),
			Util.getDuplicateArray('f16SensorR3PhasedAngle_Y_R')
		];
	},

	get sensorR3MotionAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR3MotionAngle_R_Y'),
			Util.getDuplicateArray('f16SensorR3MotionAngle_Y_G'),
			Util.getDuplicateArray('sensor_R3_motion_angle'),
			Util.getDuplicateArray('f16SensorR3MotionAngle_Max'),
			Util.getDuplicateArray('f16SensorR3MotionAngle_Min'),
			Util.getDuplicateArray('sensor_R3_motion_angle'),
			Util.getDuplicateArray('f16SensorR3MotionAngle_G_Y'),
			Util.getDuplicateArray('f16SensorR3MotionAngle_Y_R')
		];
	},

	get sensorR3Acceleration(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_R3_x_acc'),
				Util.getDuplicateArray('sensor_R3_y_acc'),
				Util.getDuplicateArray('sensor_R3_z_acc')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorR3ZAxisAcceleration(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR3ZAcc_G_Y'),
			Util.getDuplicateArray('f16SensorR3ZAcc_Y_R'),
			Util.getDuplicateArray('sensor_R3_z_acc'),
			Util.getDuplicateArray('f16SensorR3ZAcc_Max')
		];
	},

	get sensorR3Displacement(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_R3_x_disp'),
				Util.getDuplicateArray('sensor_R3_y_disp'),
				Util.getDuplicateArray('sensor_R3_z_disp')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorR3maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR3Temp_Min'),
			Util.getDuplicateArray('sensor_R3_temp'),
			Util.getDuplicateArray('f16SensorR3Temp_Max'),
			Util.getDuplicateArray('f16SensorR3Temp_G_Y'),
			Util.getDuplicateArray('f16SensorR3Temp_Y_R')
		];
	},

	get sensorR4localAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR4MainAcc_R_Y'),
			Util.getDuplicateArray('f16SensorR4MainAcc_Y_G'),
			Util.getDuplicateArray('sensor_R4_main_acc'),
			Util.getDuplicateArray('f16SensorR4MainAcc_Max'),
			Util.getDuplicateArray('f16SensorR4MainAcc_Min'),
			Util.getDuplicateArray('sensor_R4_main_acc'),
			Util.getDuplicateArray('f16SensorR4MainAcc_G_Y'),
			Util.getDuplicateArray('f16SensorR4MainAcc_Y_R')
		];
	},

	get sensorR4localRotationFrequencyCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16SensorR4Freq_R_Y'),
			Util.getDuplicateArray('u16SensorR4Freq_Y_G'),
			Util.getDuplicateArray('sensor_R4_freq'),
			Util.getDuplicateArray('u16SensorR4Freq_Max'),
			Util.getDuplicateArray('u16SensorR4Freq_Min'),
			Util.getDuplicateArray('sensor_R4_freq'),
			Util.getDuplicateArray('u16SensorR4Freq_G_Y'),
			Util.getDuplicateArray('u16SensorR4Freq_Y_R')
		];
	},

	get sensorR4localAmplitudeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR4Ampl_R_Y'),
			Util.getDuplicateArray('f16SensorR4Ampl_Y_G'),
			Util.getDuplicateArray('sensor_R4_ampl'),
			Util.getDuplicateArray('f16SensorR4Ampl_Max'),
			Util.getDuplicateArray('f16SensorR4Ampl_Min'),
			Util.getDuplicateArray('sensor_R4_ampl'),
			Util.getDuplicateArray('f16SensorR4Ampl_G_Y'),
			Util.getDuplicateArray('f16SensorR4Ampl_Y_R')
		];
	},

	get sensorR4PhasedAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR4PhasedAngle_R_Y'),
			Util.getDuplicateArray('f16SensorR4PhasedAngle_Y_G'),
			Util.getDuplicateArray('sensor_R4_phase_angle'),
			Util.getDuplicateArray('f16SensorR4PhasedAngle_Max'),
			Util.getDuplicateArray('f16SensorR4PhasedAngle_Min'),
			Util.getDuplicateArray('sensor_R4_phase_angle'),
			Util.getDuplicateArray('f16SensorR4PhasedAngle_G_Y'),
			Util.getDuplicateArray('f16SensorR4PhasedAngle_Y_R')
		];
	},

	get sensorR4MotionAngleCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR4MotionAngle_R_Y'),
			Util.getDuplicateArray('f16SensorR4MotionAngle_Y_G'),
			Util.getDuplicateArray('sensor_R4_motion_angle'),
			Util.getDuplicateArray('f16SensorR4MotionAngle_Max'),
			Util.getDuplicateArray('f16SensorR4MotionAngle_Min'),
			Util.getDuplicateArray('sensor_R4_motion_angle'),
			Util.getDuplicateArray('f16SensorR4MotionAngle_G_Y'),
			Util.getDuplicateArray('f16SensorR4MotionAngle_Y_R')
		];
	},

	get sensorR4Acceleration(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_R4_x_acc'),
				Util.getDuplicateArray('sensor_R4_y_acc'),
				Util.getDuplicateArray('sensor_R4_z_acc')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorR4ZAxisAcceleration(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR4ZAcc_G_Y'),
			Util.getDuplicateArray('f16SensorR4ZAcc_Y_R'),
			Util.getDuplicateArray('sensor_R4_z_acc'),
			Util.getDuplicateArray('f16SensorR4ZAcc_Max')
		];
	},

	get sensorR4Displacement(): {
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			timeParams: [
				Util.getDuplicateArray('sensor_R4_x_disp'),
				Util.getDuplicateArray('sensor_R4_y_disp'),
				Util.getDuplicateArray('sensor_R4_z_disp')
			],
			titleParams: ['N053XAxis', 'N053YAxis', 'N053ZAxis']
		};
	},

	get sensorR4maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16SensorR4Temp_Min'),
			Util.getDuplicateArray('sensor_R4_temp'),
			Util.getDuplicateArray('f16SensorR4Temp_Max'),
			Util.getDuplicateArray('f16SensorR4Temp_G_Y'),
			Util.getDuplicateArray('f16SensorR4Temp_Y_R')
		];
	},

	get gloabalAccelCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16MainAcc_R_Y'),
			Util.getDuplicateArray('f16MainAcc_Y_G'),
			Util.getDuplicateArray('main_acc'),
			Util.getDuplicateArray('f16MainAcc_Max'),
			Util.getDuplicateArray('f16MainAcc_Min'),
			Util.getDuplicateArray('main_acc'),
			Util.getDuplicateArray('f16MainAcc_G_Y'),
			Util.getDuplicateArray('f16MainAcc_Y_R')
		];
	},

	get gloabalFrequencyCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16MainFreq_R_Y'),
			Util.getDuplicateArray('u16MainFreq_Y_G'),
			Util.getDuplicateArray('main_freq'),
			Util.getDuplicateArray('u16MainFreq_Max'),
			Util.getDuplicateArray('u16MainFreq_Min'),
			Util.getDuplicateArray('main_freq'),
			Util.getDuplicateArray('u16MainFreq_G_Y'),
			Util.getDuplicateArray('u16MainFreq_Y_R')
		];
	},

	get gloabalAmplitudeCardCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16MainAmpl_R_Y'),
			Util.getDuplicateArray('f16MainAmpl_Y_G'),
			Util.getDuplicateArray('main_ampl'),
			Util.getDuplicateArray('f16MainAmpl_Max'),
			Util.getDuplicateArray('f16MainAmpl_Min'),
			Util.getDuplicateArray('main_ampl'),
			Util.getDuplicateArray('f16MainAmpl_G_Y'),
			Util.getDuplicateArray('f16MainAmpl_Y_R')
		];
	},

	get maxZAxisAccelerationOfSensorStructures(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16MainZAcc_G_Y'),
			Util.getDuplicateArray('f16MainZAcc_Y_R'),
			Util.getDuplicateArray('max_z_acc'),
			Util.getDuplicateArray('f16MainZAcc_Max')
		];
	},

	get maxHAxisRmsAccelerationOfBearingSensors(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16MaxHorizRms_G_Y'),
			Util.getDuplicateArray('u16MaxHorizRms_Y_R'),
			Util.getDuplicateArray('max_horiz_rms'),
			Util.getDuplicateArray('u16MaxHorizRms_Max')
		];
	},

	get maxVerticalRmsAccelerationOfBearingSensors(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16MaxVertRms_G_Y'),
			Util.getDuplicateArray('u16MaxVertRms_Y_R'),
			Util.getDuplicateArray('max_vert_rms'),
			Util.getDuplicateArray('u16MaxVertRms_Max')
		];
	},

	get maxAxialRmsAccelerationOfBearingSensors(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16MaxAxialRms_G_Y'),
			Util.getDuplicateArray('u16MaxAxialRms_Y_R'),
			Util.getDuplicateArray('max_axial_rms'),
			Util.getDuplicateArray('u16MaxAxialRms_Max')
		];
	},

	get maxBearingTemperature(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('f16MaxTemp_Min'),
			Util.getDuplicateArray('max_temp'),
			Util.getDuplicateArray('f16MaxTemp_Max'),
			Util.getDuplicateArray('f16MaxTemp_G_Y'),
			Util.getDuplicateArray('f16MaxTemp_Y_R')
		];
	},

	get newTecPalletizerInterruptCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16EmergStopCnt_day', 'u16EmergStopCnt_batch'],
				['u16AccessDoorCnt_day', 'u16AccessDoorCnt_batch'],
				['u16LightBarrierCnt_day', 'u16LightBarrierCnt_batch'],
				['u16FltBagTransCnt_day', 'u16FltBagTransCnt_batch'],
				['u16FltEmptyPalCnt_day', 'u16FltEmptyPalCnt_batch'],
				['u16FltPalletizerCnt_day', 'u16FltPalletizerCnt_batch'],
				['u16FltFullPalCnt_day', 'u16FltFullPalCnt_batch'],
				['u16FltSheetDispCnt_day', 'u16FltSheetDisp_batch'],
				['u16FltPneumaticCnt_day', 'u16FltPneumaticCnt_batch'],
				['u16FltElectricCnt_day', 'u16FltElectricCnt_batch']
			],

			timeParams: [
				['u16EmergStopTime_day', 'u16EmergStopTime_batch'],
				['u16AccessDoorTime_day', 'u16AccessDoorTime_batch'],
				['u16LightBarrierTime_day', 'u16LightBarrierTime_batch'],
				['u16FltBagTransTime_day', 'u1Flt6BagTransTime_batch'],
				['u16FltEmptyPalTime_day', 'u16FltEmptyPalTime_batch'],
				['u16FltPalletizerTime_day', 'u16FltPalletizerTime_batch'],
				['u16FltFullPalTime_day', 'u16FltFullPalTime_batch'],
				['u16FltSheetDispTime_day', 'u16FltSheetDispTime_batch'],
				['u16FltPneumaticTime_day', 'u16FltPneumaticTime_batc'],
				['u16FltElectricTime_day', 'u16FltElectricTime_batch']
			],

			titleParams: [
				'A145EmergencyStop',
				'A204SafetyDoor',
				'A205SafetyLightBarrier',
				'New001BagTransArea',
				'New002EmptyPalArea',
				'A208Palletizer',
				'New003FullPalArea',
				'New004SheetDispArea',
				'New005PneuFalut',
				'New006ElectricFault'
			]
		};
	},

	get newTecPalletizerPalletizerCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16PalAccessDoorCnt_day', 'u16PalAccessDoorCnt_batch'],
				['u16PalLightBarrierCnt_day', 'u16PalLightBarrierCnt_batch'],
				['u16PalIntroductionCnt_day', 'u16PalIntroductionCnt_batch'],
				['u16PalOrientationCnt_day', 'u16PalOrientationCnt_batch'],
				['u16PalPreGroupCnt_day', 'u16PalPreGroupCnt_batch'],
				['u16PalGroupCnt_day', 'u16PalGroupCnt_batch'],
				['u16PalRowPusherCnt_day', 'u16PalRowPusherCnt_batch'],
				['u16PalSlideTableCnt_day', 'u16PalSlideTableCnt_batch'],
				['u16PalElevatorCnt_day', 'u16PalElevatorCnt_batch'],
				['u16PalGantryCnt_day', 'u16PalGantryCnt_batch'],
				['u16PalGripperCnt_day', 'u16PalGripperCnt_batch'],
				['u16PalFilmDispCnt_day', 'u16PalFilmDispCnt_batch'],
				['u16PalFullPalTransCnt_day', 'u16PalFullPalTransCnt_batch']
			],

			timeParams: [
				['u16PalAccessDoorTime_day', 'u16PalAccessDoorTime_batch'],
				['u16PalLightBarrierTime_day', 'u16PalLightBarrierTime_batch'],
				['u16PalIntroductionTime_day', 'u16PalIntroductionTime_batch'],
				['u16PalOrientationTime_day', 'u16PalOrientationTime_batch'],
				['u16PalPreGroupTime_day', 'u16PalPreGroupTime_batch'],
				['u16PalGroupTime_day', 'u16PalGroupTime_batch'],
				['u16PalRowPusherTime_day', 'u16PalRowPusherTime_batch'],
				['u16PalSlideTableTime_day', 'u16PalSlideTableTime_batch'],
				['u16PalElevatorTime_day', 'u16PalElevatorTime_batch'],
				['u16PalGantryTime_day', 'u16PalGantryTime_batch'],
				['u16PalGripperTime_day', 'u16PalGripperTime_batch'],
				['u16PalFilmDispTime_day', 'u16PalFilmDispTime_batch'],
				['u16PalFullPalTransTime_day', 'u16PalFullPalTransTime_batch']
			],

			titleParams: [
				'A204SafetyDoor',
				'A205SafetyLightBarrier',
				'New007Introduction',
				'New008Orientation',
				'New009PreGrouping',
				'New010Grouping',
				'New011RowPusher',
				'New012SlideTable',
				'New013Elevator',
				'New014Gantry',
				'New015Gripper',
				'New016FilmDispenser',
				'New017FullPalTrans'
			]
		};
	},

	get newTecPalletizerEmptyPalleteAreaCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16EmpAccessDoorCnt_day', 'u16EmpAccessDoorCnt_batch'],
				['u16EmpLightBarrierCnt_day', 'u16EmpLightBarrierCnt_batch'],
				['u16EmpPalDispCnt_day', 'u16EmpPalDispCnt_batch'],
				['u16EmpFilmDispCnt_day', 'u16EmpFilmDispCnt_batch'],
				['u16EmpCardDispCnt_day', 'u16EmpCardDispCnt_batch'],
				['u16EmpPalTransCnt_day', 'u16EmpPalTransCnt_batch']
			],

			timeParams: [
				['u16EmpAccessDoorTime_day', 'u16EmpAccessDoorTime_batch'],
				['u16EmpLightBarrierTime_day', 'u16EmpLightBarrierTime_batch'],
				['u16EmpPalDispTime_day', 'u16EmpPalDispTime_batch'],
				['u16EmpFilmDispTime_day', 'u16EmpFilmDispTime_batch'],
				['u16EmpCardDispTime_day', 'u16EmpCardDispTime_batch'],
				['u16EmpPalTransTime_day', 'u16EmpPalTransTime_batch']
			],

			titleParams: [
				'A204SafetyDoor',
				'A205SafetyLightBarrier',
				'New018EmpPalDisp',
				'New019FilmDisp',
				'New020EmpCardDisp',
				'New021EmpPalTrans'
			]
		};
	},

	get newTecPalletizerBagTransportAreaCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16BagAccessDoorCnt_day', 'u16BagAccessDoorCnt_batch'],
				['u16BagLightBarrierCnt_day', 'u16BagLightBarrierCnt_batch'],
				['u16BagEjectingCnt_day', 'u16BagEjectingCnt_batch'],
				['u16BagMetDetectorCnt_day', 'u16BagMetDetectorCnt_batch'],
				['u16BagCheckweigherCnt_day', 'u16BagCheckweigherCnt_batch'],
				['u16BagMarkingCnt_day', 'u16BagMarkingCnt_batch'],
				['u16BagLabellingCnt_day', 'u16BagLabellingCnt_batch'],
				['u16BagTransCnt_day', 'u16BagTransCnt_batch']
			],

			timeParams: [
				['u16BagAccessDoorTime_day', 'u16BagAccessDoorTime_batch'],
				['u16BagLightBarrierTime_day', 'u16BagLightBarrierTime_batch'],
				['u16BagEjectingTime_day', 'u16BagEjectingTime_batch'],
				['u16BagMetDetectorTime_day', 'u16BagMetDetectorTime_batch'],
				['u16BagCheckweigherTime_day', 'u16BagCheckweigherTime_batch'],
				['u16BagMarkingTime_day', 'u16BagMarkingTime_batch'],
				['u16BagLabellingTime_day', 'u16BagLabellingTime_batch'],
				['u16BagTransTime_day', 'u16BagTransTime_batch']
			],

			titleParams: [
				'A204SafetyDoor',
				'A205SafetyLightBarrier',
				'New022EjectingDev',
				'New023MetDetector',
				'New041Checkweigher',
				'New024MarkingDev',
				'New025LabellingDev',
				'A206BagTransport'
			]
		};
	},

	get newTecPalletizerFullPalletAreaCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16FullAccessDoorCnt_day', 'u16FullAccessDoorCnt_batch'],
				['u16FullLightBarrierCnt_day', 'u16FullLightBarrierCnt_batch'],
				['u16FullPalDispCnt_day', 'u16FullPalDispCnt_batch'],
				['u16FullStackerCnt_day', 'u16FullStackerCnt_batch'],
				['u16FullPressCnt_day', 'u16FullPressCnt_batch'],
				['u16FullLabellingCnt_day', 'u16FullLabellingCnt_batch'],
				['u16FullWrapDevCnt_day', 'u16FullWrapDevCnt_batch'],
				['u16FullTransCnt_day', 'u16FullTransCnt_batch']
			],

			timeParams: [
				['u16FullAccessDoorTime_day', 'u16FullAccessDoorTime_batch'],
				['u16FullLightBarrierTime_day', 'u16FullLightBarrierTime_batch'],
				['u16FullPalDispTime_day', 'u16FullPalDispTime_batch'],
				['u16FullStackerTime_day', 'u16FullStackerTime_batch'],
				['u16FullPressTime_day', 'u16FullPressTime_batch'],
				['u16FullLabellingTime_day', 'u16FullLabellingTime_batch'],
				['u16FullWrapDevTime_day', 'u16FullWrapDevTime_batch'],
				['u16FullTransTime_day', 'u16FullTransTime_batch']
			],

			titleParams: [
				'A204SafetyDoor',
				'A205SafetyLightBarrier',
				'New020EmpCardDisp',
				'New026PalletStracker',
				'New027PalletPress',
				'New025LabellingDev',
				'New028Wrapper',
				'New017FullPalTrans'
			]
		};
	},

	get newTecPalletizerSheetDispenserAreaCard(): {
		countParams: Array<Array<string>>;
		timeParams: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			countParams: [
				['u16SheetAccessDoorCnt_day', 'u16SheetAccessDoorCnt_batch'],
				['u16SheetLightBarrierCnt_day', 'u16SheetLightBarrierCnt_batch'],
				['u16SheetDispCnt_day', 'u16SheetDispCnt_batch']
			],

			timeParams: [
				['u16SheetAccessDoorTime_day', 'u16SheetAccessDoorTime_batch'],
				['u16SheetLightBarrierTime_day', 'u16SheetLightBarrierTime_batch'],
				['u16SheetDispTime_day', 'u16SheetDispTime_batch']
			],

			titleParams: [
				'A204SafetyDoor',
				'A205SafetyLightBarrier',
				'New020EmpCardDisp'
			]
		};
	},

	get newTecPalletizerProductionCard(): Array<Array<string>> {
		return [
			['u8ShowUpPrograms', 'u8ShowUpPrograms'],
			['u32ProductNumber1', 'u32ProductNumber1'],
			['u32ProductNumber2', 'u32ProductNumber2'],
			['u32ProductNumber3', 'u32ProductNumber3'],
			['u32ProductNumber4', 'u32ProductNumber4'],
			['u32ProductNumber5', 'u32ProductNumber5'],
			['u32ProductNumber6', 'u32ProductNumber6'],
			['u32ProductNumber7', 'u32ProductNumber7'],
			['u32ProductNumber8', 'u32ProductNumber8'],
			['u32ProductNumber9', 'u32ProductNumber9'],
			['u32ProductNumber10', 'u32ProductNumber10'],
			['u32ProductNumber11', 'u32ProductNumber11'],
			['u32ProductNumber12', 'u32ProductNumber12'],
			['u32ProductNumber13', 'u32ProductNumber13'],
			['u32ProductNumber14', 'u32ProductNumber14'],
			['u32ProductNumber15', 'u32ProductNumber15'],
			['u32ProductNumber16', 'u32ProductNumber16'],
			['u32ProductNumber17', 'u32ProductNumber17'],
			['u32ProductNumber18', 'u32ProductNumber18'],
			['u32ProductNumber19', 'u32ProductNumber19'],
			['u32ProductNumber20', 'u32ProductNumber20'],

			['u32FullPalletCnt1', 'u32FullPalletCnt1'],
			['u32FullPalletCnt2', 'u32FullPalletCnt2'],
			['u32FullPalletCnt3', 'u32FullPalletCnt3'],
			['u32FullPalletCnt4', 'u32FullPalletCnt4'],
			['u32FullPalletCnt5', 'u32FullPalletCnt5'],
			['u32FullPalletCnt6', 'u32FullPalletCnt6'],
			['u32FullPalletCnt7', 'u32FullPalletCnt7'],
			['u32FullPalletCnt8', 'u32FullPalletCnt8'],
			['u32FullPalletCnt9', 'u32FullPalletCnt9'],
			['u32FullPalletCnt10', 'u32FullPalletCnt10'],
			['u32FullPalletCnt11', 'u32FullPalletCnt11'],
			['u32FullPalletCnt12', 'u32FullPalletCnt12'],
			['u32FullPalletCnt13', 'u32FullPalletCnt13'],
			['u32FullPalletCnt14', 'u32FullPalletCnt14'],
			['u32FullPalletCnt15', 'u32FullPalletCnt15'],
			['u32FullPalletCnt16', 'u32FullPalletCnt16'],
			['u32FullPalletCnt17', 'u32FullPalletCnt17'],
			['u32FullPalletCnt18', 'u32FullPalletCnt18'],
			['u32FullPalletCnt19', 'u32FullPalletCnt19'],
			['u32FullPalletCnt20', 'u32FullPalletCnt20'],

			['u8PalletProdPercent1', 'u8PalletProdPercent1'],
			['u8PalletProdPercent2', 'u8PalletProdPercent2'],
			['u8PalletProdPercent3', 'u8PalletProdPercent3'],
			['u8PalletProdPercent4', 'u8PalletProdPercent4'],
			['u8PalletProdPercent5', 'u8PalletProdPercent5'],
			['u8PalletProdPercent6', 'u8PalletProdPercent6'],
			['u8PalletProdPercent7', 'u8PalletProdPercent7'],
			['u8PalletProdPercent8', 'u8PalletProdPercent8'],
			['u8PalletProdPercent9', 'u8PalletProdPercent9'],
			['u8PalletProdPercent10', 'u8PalletProdPercent10'],
			['u8PalletProdPercent11', 'u8PalletProdPercent11'],
			['u8PalletProdPercent12', 'u8PalletProdPercent12'],
			['u8PalletProdPercent13', 'u8PalletProdPercent13'],
			['u8PalletProdPercent14', 'u8PalletProdPercent14'],
			['u8PalletProdPercent15', 'u8PalletProdPercent15'],
			['u8PalletProdPercent16', 'u8PalletProdPercent16'],
			['u8PalletProdPercent17', 'u8PalletProdPercent17'],
			['u8PalletProdPercent18', 'u8PalletProdPercent18'],
			['u8PalletProdPercent19', 'u8PalletProdPercent19'],
			['u8PalletProdPercent20', 'u8PalletProdPercent20']
		];
	},

	get moreMaintenanceCard() {
		const aMaintCards = [];

		// aMaintCards0.....aMaintCards399
		Array.from({length: 400}, (v, k) => k).forEach((index) => {
			aMaintCards.push(`aMaintCards${index}`);
		});
		return aMaintCards;
	},

	get capacityAbsoluteCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16CapacityAbsolut_R_Y_1'),
			Util.getDuplicateArray('u16CapacityAbsolut_Y_G_1'),
			Util.getDuplicateArray('u16CapacityAbsolut'),
			Util.getDuplicateArray('u16CapacityAbsolut_Max'),
			Util.getDuplicateArray('u16CapacityAbsolut_Min'),
			Util.getDuplicateArray('u16CapacityAbsolut'),
			Util.getDuplicateArray('u16CapacityAbsolut_Y_G_2'),
			Util.getDuplicateArray('u16CapacityAbsolut_R_Y_2')
		];
	},

	get capacityRelativeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16CapacityRelative_R_Y_1'),
			Util.getDuplicateArray('u16CapacityRelative_Y_G_1'),
			Util.getDuplicateArray('u16CapacityRelative'),
			Util.getDuplicateArray('u16CapacityRelativ_Max'),
			Util.getDuplicateArray('u16CapacityRelativ_Min'),
			Util.getDuplicateArray('u16CapacityRelative'),
			Util.getDuplicateArray('u16CapacityRelative_Y_G_2'),
			Util.getDuplicateArray('u16CapacityRelative_R_Y_2')
		];
	},

	get productAbsoluteCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16ProductInRangeAbsolut_R_Y_1'),
			Util.getDuplicateArray('u16ProductInRangeAbsolut_Y_G_1'),
			Util.getDuplicateArray('u16ProductInRangeAbsolut'),
			Util.getDuplicateArray('u16ProductInRangeAbsolut_Max')
		];
	},

	get productRelativeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16ProductInRangeRelative_R_Y_1'),
			Util.getDuplicateArray('u16ProductInRangeRelative_Y_G_1'),
			Util.getDuplicateArray('u16ProductInRange'),
			Util.getDuplicateArray('u16ProductInRangeRelativ_Max')
		];
	},

	get undersizedAbsoluteCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16UndersizedAbsolut_R_Y_1'),
			Util.getDuplicateArray('u16UndersizedAbsolut_Y_G_1'),
			Util.getDuplicateArray('u16UndersizedAbsolut'),
			Util.getDuplicateArray('u16UndersizedAbsolut_Max')
		];
	},

	get undersizedRelativeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16UndersizedRelative_R_Y_1'),
			Util.getDuplicateArray('u16UndersizedRelative_Y_G_1'),
			Util.getDuplicateArray('u16UndersizedRange'),
			Util.getDuplicateArray('u16UndersizedRelativ_Max')
		];
	},

	get oversizedAbsoluteCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16OversizedAbsolut_R_Y_1'),
			Util.getDuplicateArray('u16OversizedAbsolut_Y_G_1'),
			Util.getDuplicateArray('u16OversizedAbsolut'),
			Util.getDuplicateArray('u16OversizedAbsolut_Max')
		];
	},

	get oversizedRelativeCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16OversizedRelative_R_Y_1'),
			Util.getDuplicateArray('u16OversizedRelative_Y_G_1'),
			Util.getDuplicateArray('u16OversizedRange'),
			Util.getDuplicateArray('u16OversizedRelativ_Max')
		];
	},

	get moistureCardVariables(): Array<Array<string>> {
		return [
			Util.getDuplicateArray('u16Moisture_R_Y_1'),
			Util.getDuplicateArray('u16Moisture_Y_G_1'),
			Util.getDuplicateArray('u16Moisture'),
			Util.getDuplicateArray('u16MoistureMax'),
			Util.getDuplicateArray('u16MoistureMin'),
			Util.getDuplicateArray('u16Moisture'),
			Util.getDuplicateArray('u16Moisture_Y_G_2'),
			Util.getDuplicateArray('u16Moisture_R_Y_2')
		];
	},

	get productionAbsoluteMachineCard(): {
		variables: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			variables: [
				['u32ProdDay', 'u32ProdBatch'],
				['u32ProdDayInRangeAbsolut', 'u32ProdBatchInRangeAbsolut'],
				['u32ProdAll', 'u32ProdAll'],
				['u32ProdInRangeAll', 'u32ProdInRangeAll']
			],

			titleParams: [
				'A015ProductionToday',
				'A015ProductionToday',
				'A017RemProduction',
				'A017RemProduction'
			]
		};
	},

	get productionRelativeMachineCard(): {
		variables: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			variables: [
				['u32ProdDay', 'u32ProdBatch'],
				['u32ProdDayInRangeRelativ', 'u32ProdBatchInRangeRelative'],
				['u32ProdAll', 'u32ProdAll'],
				['u32ProdInRangeAll', 'u32ProdInRangeAll']
			],

			titleParams: [
				'A015ProductionToday',
				'A015ProductionToday',
				'A017RemProduction',
				'A017RemProduction'
			]
		};
	},

	get temperaturesMachineCard(): {
		variables: Array<Array<string>>;
		titleParams: Array<string>;
	} {
		return {
			variables: [
				['u16BearingTemp1', 'u16BearingTemp1'],
				['u16BearingTemp2', 'u16BearingTemp2'],
				['u16DriveTemp', 'u16DriveTemp'],
				['u16ConverterTemp', 'u16ConverterTemp']
			],

			titleParams: [
				'N113BearingTemp1',
				'N114BearingTemp2',
				'N115DriveTemp',
				'N116ConvTemp'
			]
		};
	},

	get energyVoltageCard(): {
		headline: string;
		titleParams: Array<string>;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A656Voltage',

			titleParams: [
				'A658U1',
				'A659U2',
				'A660U3',
				'A661U12',
				'A662U23',
				'A663U31',
			],

			neededValues: [
				Util.getDuplicateArray('f32VoltageU1'),
				Util.getDuplicateArray('f32VoltageU2'),
				Util.getDuplicateArray('f32VoltageU3'),
				Util.getDuplicateArray('f32VoltageU12'),
				Util.getDuplicateArray('f32VoltageU23'),
				Util.getDuplicateArray('f32VoltageU31'),
			],

			unit: 'A657Volt'
		};
	},

	get energyCurrentCard(): {
		headline: string;
		titleParams: Array<string>;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A664Current',

			titleParams: [
				'A666I1',
				'A667I2',
				'A668I3',
			],

			neededValues: [
				Util.getDuplicateArray('f32CurrentI1'),
				Util.getDuplicateArray('f32CurrentI2'),
				Util.getDuplicateArray('f32CurrentI3'),
			],

			unit: 'A665Amps'
		};
	},

	get energyFrequencyCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A669Frequency',
			neededValues: [Util.getDuplicateArray('f32Frequency')],
			unit: 'A670Hertz'
		};
	},

	get energyPressureCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A671Pressure',
			neededValues: [Util.getDuplicateArray('f32Pressure')],
			unit: 'A672Bar'
		};
	},

	get energyMoistureCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A673Moisture',
			neededValues: [Util.getDuplicateArray('f32Moisture')],
			unit: 'A629Percent'
		};
	},

	get energyTemperatureCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'N057Temperature',
			neededValues: [Util.getDuplicateArray('f32Temperature')],
			unit: 'N057DegreeCelcius'
		};
	},

	get energyAirflowCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A098CompAirFlow',
			neededValues: [Util.getDuplicateArray('f32AirFlow')],
			unit: 'A674AirFlowUnit'
		};
	},

	get energyAnnualPowerConsumptionCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A701PowerConsumption',
			neededValues: [Util.getDuplicateArray('f32EffectivePowerReferenceOverall')],
			unit: 'A309KWH'
		};
	},

	get energyAnnualAirConsumptionCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A702AirConsumption',
			neededValues: [Util.getDuplicateArray('f32AirConsumptionOverall')],
			unit: 'A674AirFlowUnit'
		};
	},

	get energyMonthlyPowerConsumptionCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A701PowerConsumption',
			neededValues: [Util.getDuplicateArray('f32EffectivePowerReferenceOverall')],
			unit: 'A309KWH'
		};
	},

	get energyMonthlyAirConsumptionCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A702AirConsumption',
			neededValues: [Util.getDuplicateArray('f32AirConsumptionOverall')],
			unit: 'A674AirFlowUnit'
		};
	},

	get energyTimespanPowerConsumptionCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A701PowerConsumption',
			neededValues: [Util.getDuplicateArray('f32EffectivePowerReferenceOverall')],
			unit: 'A309KWH'
		};
	},

	get energyTimespanAirConsumptionCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A702AirConsumption',
			neededValues: [Util.getDuplicateArray('f32AirConsumptionOverall')],
			unit: 'A674AirFlowUnit'
		};
	},

	get energyPowerEfficiencyCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A678PowerEfficiency',
			neededValues: [
				Util.getDuplicateArray('u32ProdAll'),
				Util.getDuplicateArray('f32EffectivePowerReferenceOverall'),
				Util.getDuplicateArray('u16SortNumber'),
				Util.getDuplicateArray('bPowerEfficiencyReset')
			],
			unit: 'A679BagsPerKWH'
		};
	},

	get energyAirEfficiencyCard(): {
		headline: string;
		neededValues: Array<Array<string>>;
		unit: string;
	} {
		return {
			headline: 'A685AirEfficiency',
			neededValues: [
				Util.getDuplicateArray('u32ProdAll'),
				Util.getDuplicateArray('f32AirConsumptionOverall'),
				Util.getDuplicateArray('u16SortNumber'),
				Util.getDuplicateArray('bAirEfficiencyReset')
			],
			unit: 'A686BagsPerAirFlowUnit'
		};
	},
};
